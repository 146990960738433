<div class="overflow-x-hidden">
    <!-- Banner Area -->
    <div class="banner-are">
        <owl-carousel-o
            [options]="customOptions"
            (changed)="onSlideChanged($event)"
        >
            <ng-container *ngFor="let slide of images">
                <ng-template carouselSlide [id]="slide.id">
                    <img
                        [src]="slide.src"
                        height="450px"
                        [ngStyle]="{ 'object-fit': slide.id===1 ? '' : 'cover' }"
                        [alt]="slide.alt"
                        [title]="slide.title"
                    />

                    <div
                        *ngIf="slide.id !== 3"
                        class="sm-font top-70"
                        [class]="getSlideTitleClass(slide.id)"
                    >
                        {{ slide.title }}
                    </div>
                    <div
                        *ngIf="slide.id === 1"
                        class="slide-title-right sm-font"
                        [class]="getSlideTitleClass(slide.id, 'right')"
                    >
                        Priti Valve
                        <p class="text-white fs-6">
                            (Manufacturers of Industrial Valves & <br />Sanitary
                            Fittings)
                        </p>
                    </div>
                    <div
                        *ngIf="slide.id === 3"
                        class="sm-font top-70"
                        [class]="getSlideTitleClass(slide.id)"
                    >
                        Fulfilling All The Needs of<br />Domestic & Industrial
                        Users
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
    <!-- End Banner Area -->

    <!-- About Area -->
    <div class="about-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <img
                        class="valve-img-md d-lg-block"
                        src="assets/img/valvePriti.png"
                        alt="Images"
                    />
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <h2>
                            <b>{{ "HOME_ABOUT" | translate }}</b>
                        </h2>

                        <img
                            class="valve-img-sm d-lg-none"
                            src="assets/img/valvePriti.png"
                            alt="Images"
                        />

                        <p>
                            {{ "HOME_ABOUT_DETAILS" | translate }}
                        </p>

                        <div class="choose-item">
                            <div class="choose-item-list">
                                <ul>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        {{
                                            "HOME_ABOUT_DETAILS_LI_1"
                                                | translate
                                        }}
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        {{
                                            "HOME_ABOUT_DETAILS_LI_2"
                                                | translate
                                        }}
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        {{
                                            "HOME_ABOUT_DETAILS_LI_3"
                                                | translate
                                        }}
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        {{
                                            "HOME_ABOUT_DETAILS_LI_4"
                                                | translate
                                        }}
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        {{
                                            "HOME_ABOUT_DETAILS_LI_5"
                                                | translate
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="about-btn">
                            <a routerLink="/about" class="learn-btn">
                                {{ "BTN_LEARN_MORE" | translate }}</a
                            >
                            <a routerLink="/contact" class="get-btn">
                                {{ "BTN_CONTACT_US" | translate }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About Area End -->

    <!-- Choose Area -->
    <div class="choose-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div
                        class="section-title about-content"
                    >
                        <h2>
                            <b>{{ "CHOOSE_US" | translate }}</b>
                        </h2>
                    </div>
                    <div class="tab choose-tab mb-3">
                        <ul class="tabs">
                            <li>
                                <a href="#">{{
                                    "BTN_OUR_VISION" | translate
                                }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="choose-item-content">
                        <p class="text-justify">
                            {{ "OUR_VISION_P1" | translate }}<br /><br />
                            {{ "OUR_VISION_P2" | translate }}
                        </p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="section-title about-content visibility-hidden choose-us">
                        <h2>
                            <b>{{ "CHOOSE_US" | translate }}</b>
                        </h2>
                    </div>

                    <div class="tab choose-tab mb-3">
                        <ul class="tabs">
                            <li>
                                <a href="#"  class="bg-white text-dark">{{
                                    "BTN_OUR_MISSION" | translate
                                }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="choose-item-content">
                        <p class="text-justify">
                            {{ "OUR_MISSION_P1" | translate }}<br /><br />
                            {{ "OUR_MISSION_P2" | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Choose Area End -->

    <!-- Solutions Area -->
    <div class="solutions-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div
                    class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4 sm-center border-white-left"
                >
                    <div class="plus">
                        <div class="d-flex align-items-center gap-5">
                            <h1>38+</h1>
                            <i class="flaticon flaticon-team"></i>
                        </div>
                        <div class="solution-title">
                            {{ "YEARS_EXPERIENCE" | translate }}
                        </div>
                    </div>
                </div>

                <div
                    class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4 sm-center border-white-left"
                >
                    <div class="plus">
                        <div class="d-flex align-items-center gap-5">
                            <h1>{{ "100" | translate }}+</h1>
                            <i class="flaticon flaticon-people"></i>
                        </div>
                        <div class="solution-title">
                            {{ "PASSIONATE_EMPLOYEE" | translate }}
                        </div>
                    </div>
                </div>
                <div
                    class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4 sm-center border-white-left"
                >
                    <div class="plus">
                        <div class="d-flex align-items-center gap-5">
                            <h1>25+</h1>
                            <i class="flaticon flaticon-chess-pieces"></i>
                        </div>
                        <div class="solution-title">
                            {{ "AGENTS_NATIONWIDE" | translate }}
                        </div>
                    </div>
                </div>

                <div
                    class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4 sm-center border-white-left"
                >
                    <div class="plus">
                        <div class="d-flex align-items-center gap-5">
                            <h1>{{ "100" | translate }}+</h1>
                            <i class="flaticon flaticon-analytics"></i>
                        </div>
                        <div class="solution-title">
                            {{ "PROJECTS" | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Solutions Area End -->

    <!-- Project Area -->
    <div class="project-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="project-title">
                        <div class="section-title about-content">
                            <h2>
                                <b>{{ "PRODUCTS" | translate }}</b>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab project-tab">
                <ul class="tabs">
                    <li>
                        <a href="#">{{ "VALVE_FITTINGS" | translate }}</a>
                    </li>
                    <li>
                        <a href="#">{{ "SANITARY" | translate }}</a>
                    </li>
                </ul>

                <div class="tab_content current active">
                    <div class="tabs_item current">
                        <div class="project-tab-item">
                            <div class="row pt-45">
                                <div class="col-lg-4 col-md-6">
                                    <div class="service-item">
                                        <div class="images">
                                            <a
                                                ><img
                                                    src="assets/img/products/Brass Bib Cock NS 361.png"
                                                    alt="Images"
                                            /></a>
                                            <a class="service-item-icon"
                                                ><i
                                                    class="fa-solid fa-filter"
                                                ></i
                                            ></a>
                                            <a
                                                class="service-item-more"
                                                routerLink="/product-details/brass-bib-tap"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>

                                        <div
                                            class="content"
                                            routerLink="/product-details/brass-bib-tap"
                                        >
                                            <h3 title="Brass Bib Cock NS 362">
                                                <a>Brass Bib Cock NS 362</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service-item">
                                        <div class="images">
                                            <a
                                                ><img
                                                    src="assets/img/products/Gunmetal Gate Valve NS 149 three.png"
                                                    alt="Images"
                                            /></a>
                                            <a class="service-item-icon"
                                                ><i
                                                    class="fa-solid fa-filter"
                                                ></i
                                            ></a>
                                            <a
                                                class="service-item-more"
                                                routerLink="/product-details/gate-valve"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>

                                        <div
                                            class="content"
                                            routerLink="/product-details/gate-valve"
                                        >
                                            <h3>
                                                <a
                                                    class="white-space-no-wrap"
                                                    title="Gunmetal Gate Valve NS 149"
                                                    >Gunmetal Gate Valve
                                                    NS...</a
                                                >
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service-item">
                                        <div class="images">
                                            <a
                                                ><img
                                                    src="assets/img/products/Gunmetal Globe Valve NS 382 two.png"
                                                    alt="Images"
                                            /></a>
                                            <a class="service-item-icon"
                                                ><i
                                                    class="fa-solid fa-filter"
                                                ></i
                                            ></a>
                                            <a
                                                class="service-item-more"
                                                routerLink="/product-details/globe-valve"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>

                                        <div
                                            class="content"
                                            routerLink="/product-details/globe-valve"
                                        >
                                            <h3
                                                title="Gun Metal Globe Valve NS 382"
                                            >
                                                <a class="white-space-no-wrap"
                                                    >Gun Metal Globe Valve
                                                    NS...</a
                                                >
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="about-content d-flex justify-content-center"
                            >
                                <div class="about-btn">
                                    <a
                                        routerLink="/products/valve-fittings"
                                        class="learn-btn"
                                        >View More</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabs_item current">
                        <div class="project-tab-item">
                            <div class="row pt-45">
                                <div class="col-lg-4 col-md-6">
                                    <div class="service-item">
                                        <div class="images">
                                            <div
                                                id="carouselExample"
                                                class="carousel slide"
                                            >
                                                <div class="carousel-inner">
                                                    <div
                                                        class="carousel-item active"
                                                    >
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Angle Cock one.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Angle Cock two.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Angle Cock three.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                </div>
                                                <button
                                                    class="carousel-control-prev"
                                                    type="button"
                                                    data-bs-target="#carouselExample"
                                                    data-bs-slide="prev"
                                                >
                                                   <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                    <span
                                                        class="visually-hidden"
                                                        >Previous</span
                                                    >
                                                </button>
                                                <button
                                                    class="carousel-control-next"
                                                    type="button"
                                                    data-bs-target="#carouselExample"
                                                    data-bs-slide="next"
                                                >
                                                    <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                    <span
                                                        class="visually-hidden"
                                                        >Next</span
                                                    >
                                                </button>
                                            </div>

                                            <a
                                                routerLink="/coming-soon"
                                                class="service-item-icon"
                                                ><i
                                                    class="fa-solid fa-filter"
                                                ></i
                                            ></a>
                                            <a class="service-item-more"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>

                                        <div
                                            class="content"
                                            routerLink="/coming-soon"
                                        >
                                            <h3>
                                                <a title="CP Angle Cock"
                                                    >CP Angle Cock</a
                                                >
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service-item">
                                        <div class="images">
                                            <div
                                                id="carouselExample1"
                                                class="carousel slide"
                                            >
                                                <div class="carousel-inner">
                                                    <div
                                                        class="carousel-item active"
                                                    >
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Short Body one.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Short Body two.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP short body three.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>

                                                    <div class="carousel-item">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Short Body four.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                </div>
                                                <button
                                                    class="carousel-control-prev"
                                                    type="button"
                                                    data-bs-target="#carouselExample1"
                                                    data-bs-slide="prev"
                                                >
                                                   <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                    <span
                                                        class="visually-hidden"
                                                        >Previous</span
                                                    >
                                                </button>
                                                <button
                                                    class="carousel-control-next"
                                                    type="button"
                                                    data-bs-target="#carouselExample1"
                                                    data-bs-slide="next"
                                                >
                                                    <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                    <span
                                                        class="visually-hidden"
                                                        >Next</span
                                                    >
                                                </button>
                                            </div>

                                            <a
                                                routerLink="/coming-soon"
                                                class="service-item-icon"
                                                ><i
                                                    class="fa-solid fa-filter"
                                                ></i
                                            ></a>
                                            <a class="service-item-more"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>

                                        <div
                                            class="content"
                                            routerLink="/coming-soon"
                                        >
                                            <h3>
                                                <a title="CP Short Body"
                                                    >CP Short Body</a
                                                >
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service-item">
                                        <div class="images">
                                            <div
                                                id="carouselExample2"
                                                class="carousel slide"
                                            >
                                                <div class="carousel-inner">
                                                    <div
                                                        class="carousel-item active"
                                                    >
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Long Body one.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/CP Long Body two.png"
                                                                alt="Images"
                                                        /></a>
                                                    </div>
                                                </div>
                                                <button
                                                    class="carousel-control-prev"
                                                    type="button"
                                                    data-bs-target="#carouselExample2"
                                                    data-bs-slide="prev"
                                                >
                                                   <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                    <span
                                                        class="visually-hidden"
                                                        >Previous</span
                                                    >
                                                </button>
                                                <button
                                                    class="carousel-control-next"
                                                    type="button"
                                                    data-bs-target="#carouselExample2"
                                                    data-bs-slide="next"
                                                >
                                                    <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                    <span
                                                        class="visually-hidden"
                                                        >Next</span
                                                    >
                                                </button>
                                            </div>

                                            <a
                                                routerLink="/coming-soon"
                                                class="service-item-icon"
                                                ><i
                                                    class="fa-solid fa-filter"
                                                ></i
                                            ></a>
                                            <a class="service-item-more"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>

                                        <div
                                            class="content"
                                            routerLink="/coming-soon"
                                        >
                                            <h3>
                                                <a title="CP Long Body"
                                                    >CP Long Body</a
                                                >
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="about-content d-flex justify-content-center"
                            >
                                <div class="about-btn">
                                    <a
                                        routerLink="/products/sanitary"
                                        class="learn-btn"
                                        >View More</a
                                    >

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Project Area End -->

    <!-- Client Area -->
    <div class="client-area pt-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="client-title">
                        <div class="section-title text-center">
                            <h2><span>Our Clients</span></h2>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="myCarousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
                data-bs-interval="3000"
            >
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row">
                            <div
                                class="col-lg-4 col-md-6"
                                *ngFor="let image of clientImages.slice(0, 3)"
                            >
                                <div class="client-item">
                                    <div class="client-img">
                                        <img
                                            src="{{ image.src }}"
                                            alt="Images"
                                        />
                                    </div>

                                    <div class="content">
                                        <h3>{{ image.title }}</h3>

                                        <p class="mt-3">
                                            {{ image.desc }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div
                                class="col-lg-4 col-md-6"
                                *ngFor="let image of clientImages.slice(3, 6)"
                            >
                                <div class="client-item">
                                    <div class="client-img">
                                        <img
                                            src="{{ image.src }}"
                                            alt="Images"
                                        />
                                    </div>

                                    <div class="content">
                                        <h3>{{ image.title }}</h3>

                                        <p class="mt-3">
                                            {{ image.desc }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div
                                class="col-lg-4 col-md-6"
                                *ngFor="let image of clientImages.slice(6, 9)"
                            >
                                <div class="client-item">
                                    <div class="client-img">
                                        <img
                                            src="{{ image.src }}"
                                            alt="Images"
                                        />
                                    </div>

                                    <div class="content">
                                        <h3>{{ image.title }}</h3>

                                        <p class="mt-3">
                                            {{ image.desc }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide="prev"
                >
                <i
                class="fa-solid fa-angle-left"
                aria-hidden="true"
            ></i>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide="next"
                >
                <i
                class="fa-solid fa-angle-right"
                aria-hidden="true"
            ></i>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
    <!-- Client Area End -->
</div>

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-globe-valve',
    templateUrl: './globe-valve.component.html',
    styleUrls: ['./globe-valve.component.scss']
})
export class GlobeValveComponent extends AppComponentBase {
    constructor(private title: Title) {
        super();
        this.title.setTitle(`Gunmetal Globe Valve NS 382 | ${this.companyName}`)
    }

    images = [
        { id: 1, src: "assets/img/products/Gunmetal Globe Valve NS 382 one.png", alt: 'glabeValve img' },
        { id: 2, src: "assets/img/products/Gunmetal Globe Valve NS 382 two.png", alt: 'glabeValve img' },
        { id: 3, src: 'assets/img/products/Gunmetal Globe Valve NS 382 three.png', alt: 'glabeValve img' }

    ]
}

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Gunmetal Gate Valve NS 149
                </h4>

                <div>NS-149/044 <sub>9</sub>, NS MARKED</div>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/GUNMETAL GATE VALVE NS 149.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img
                                    style="margin-left: 95px"
                                    [src]="slide.src"
                                    [alt]="slide.alt"
                                />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">Specification</div>
                    </div>

                    <div class="py-3 pl-14px">
                        PRITI&quot; Gun Metal Wedge Gate Valve, screwed in
                        bonnet, inside screw, non-rising stem, integral seats;
                        screwed female ends (BSP) to IS:554, handwheel operated;
                        conforming to NS-149/044.
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Body Test Pressure = 22 kg f/cm<sup>2</sup>
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Seat &amp; Backseat Test Pressure = 17.5 kg
                                    f/cm<sup>2</sup>
                                </li>
                                <li>
                                    <i class="bx bx-check"></i> All Valves are
                                    supplied duly NS Marked.
                                </li>
                            </ul>
                            <p class="mt-3">
                                Our Works Test Certificate is furnished with
                                every supply.
                            </p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials">
            <h5>Materials</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                            <th>Material Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="m-td">
                                Body, Bonnet, Wedge, Stuffing Box, Gland, Gland
                                Nut
                            </td>
                            <td class="m-td">Gun Metal/Leaded Tin Bronzes</td>
                            <td class="m-td">GM/LTB2</td>
                        </tr>

                        <tr>
                            <td class="m-td">Spindle</td>
                            <td class="m-td">Forged Brass</td>
                            <td class="m-td">FB</td>
                        </tr>

                        <tr>
                            <td class="m-td">Handwheel</td>
                            <td class="m-td">Cast Iron</td>
                            <td class="m-td">CI</td>
                        </tr>
                        <tr>
                            <td class="m-td">Gland Packing</td>
                            <td class="m-td">Asbestos</td>
                            <td class="m-td">ASB</td>
                        </tr>
                        <tr>
                            <td class="m-td">
                                Screw &amp; Washer for Handwheel
                            </td>
                            <td class="m-td">Mild Steel</td>
                            <td class="m-td">Ms</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <h5>Dimensions (in mm)</h5>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Nominal Size</th>
                                <th>F</th>
                                <th>H</th>
                                <th>W</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="d-td">8</td>
                                <td class="d-td">50</td>
                                <td class="d-td">80</td>
                                <td class="d-td">44</td>
                            </tr>

                            <tr>
                                <td class="d-td">10</td>
                                <td class="d-td">50</td>
                                <td class="d-td">85</td>
                                <td class="d-td">44</td>
                            </tr>

                            <tr>
                                <td class="d-td">15</td>
                                <td class="d-td">54</td>
                                <td class="d-td">95</td>
                                <td class="d-td">50</td>
                            </tr>

                            <tr>
                                <td class="d-td">20</td>
                                <td class="d-td">60</td>
                                <td class="d-td">105</td>
                                <td class="d-td">55</td>
                            </tr>

                            <tr>
                                <td class="d-td">25</td>
                                <td class="d-td">70</td>
                                <td class="d-td">125</td>
                                <td class="d-td">65</td>
                            </tr>

                            <tr>
                                <td class="d-td">32</td>
                                <td class="d-td">80</td>
                                <td class="d-td">135</td>
                                <td class="d-td">65</td>
                            </tr>

                            <tr>
                                <td class="d-td">40</td>
                                <td class="d-td">90</td>
                                <td class="d-td">150</td>
                                <td class="d-td">85</td>
                            </tr>

                            <tr>
                                <td class="d-td">50</td>
                                <td class="d-td">100</td>
                                <td class="d-td">175</td>
                                <td class="d-td">95</td>
                            </tr>

                            <tr>
                                <td class="d-td">65</td>
                                <td class="d-td">105</td>
                                <td class="d-td">210</td>
                                <td class="d-td">120</td>
                            </tr>

                            <tr>
                                <td class="d-td">80</td>
                                <td class="d-td">125</td>
                                <td class="d-td">250</td>
                                <td class="d-td">120</td>
                            </tr>

                            <tr>
                                <td class="d-td">100</td>
                                <td class="d-td">160</td>
                                <td class="d-td">300</td>
                                <td class="d-td">140</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="product-imgage mt-1 text-center">
                    <img
                        width="200px"
                        src="assets/img/products/Products Vien Diagram/Gunmetal Gate Valve NS 149/Gunmetal Gate Valve NS 149-1.png"
                        alt="Images"
                    />
                </div>
            </div>
            <div class="notes mt-2">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to continual
                        design improvements. Please confirm before placing
                        order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

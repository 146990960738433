import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from 'src/app/components/shared/AppComponentBase';

@Component({
    selector: 'app-brass-union-unicef',
    templateUrl: './brass-union-unicef.component.html',
    styleUrls: ['./brass-union-unicef.component.scss']
})
export class BrassUnionUnicefComponent extends AppComponentBase {

    images = [
        { id: 1, src: "assets/img/products/Brass Union UNF type one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Brass Union UNF type two.png", alt: 'img' },
    ]

    constructor(private title: Title) {
        super();
        this.title.setTitle(`Brass Union UNF Type | ${this.companyName}`)
    }
}

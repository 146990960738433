import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-brass-ball-valve',
    templateUrl: './brass-ball-valve.component.html',
    styleUrls: ['./brass-ball-valve.component.scss']
})
export class BrassBallValveComponent extends AppComponentBase {
    images = [
        { id: 1, src: "assets/img/products/Brass Ball Valve Butterfly type one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Brass Ball Valve Butterfly Type two.png", alt: 'img' },
        { id: 3, src: 'assets/img/products/Brass Ball Valve Butterfly type three.png', alt: 'img' }

    ]

    constructor(private title: Title) {
        super();
        this.title.setTitle(`Brass Ball Valve Butterfly Type | ${this.companyName}`)
    }
}

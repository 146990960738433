import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
  selector: 'app-flow-regulating-valve',
  templateUrl: './flow-regulating-valve.component.html',
  styleUrls: ['./flow-regulating-valve.component.scss']
})
export class FlowRegulatingValveComponent extends AppComponentBase {
    constructor(private title: Title) {
        super();
        this.title.setTitle(`Flow Control Valve | ${this.companyName}`)
    }

    images = [
        { id: 1, src: "assets/img/products/Priti Regulating Flow Control Valve one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Priti Regulating Flow Control Valve two.png", alt: 'img' },
        { id: 3, src: "assets/img/products/Priti Regulating Flow Control Valve three.png", alt: 'img' },
    ]
}

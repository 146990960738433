import { Component } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-brass-bib-tap',
  templateUrl: './brass-bib-tap.component.html',
  styleUrls: ['./brass-bib-tap.component.scss']
})
export class BrassBibTapComponent extends AppComponentBase {

    constructor(private title: Title) {
        super();
        this.title.setTitle(`BRASS BIB COCK NS 362 | ${this.companyName}`)
    }
}

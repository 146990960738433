import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-gate-valve',
    templateUrl: './gate-valve.component.html',
    styleUrls: ['./gate-valve.component.scss']
})
export class GateValveComponent extends AppComponentBase {
    constructor(private title: Title) {
        super();
        this.title.setTitle(`Gunmetal Gate Valve NS 149 | ${this.companyName}`)
    }

    images = [
        { id: 1, src: "assets/img/products/Gunmetal Gate Valve NS 149 one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Gunmetal Gate Valve NS 149 two.png", alt: 'img' },
        { id: 3, src: 'assets/img/products/Gunmetal Gate Valve NS 149 three.png', alt: 'img' }
    ]
}

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Brass Meter Ball Valve
                </h4>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/BRASS METER BALL VALVE.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img
                                    style="margin-left: 95px"
                                    [src]="slide.src"
                                    [alt]="slide.alt"
                                />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">
                            Product Description
                        </div>
                    </div>

                    <div class="py-3 pl-14px">
                        <ul class="list-unstyled">
                            <li>
                                <strong>Product:</strong> Priti Brass Water
                                Meter Valve
                            </li>

                            <li>
                                <strong>Body Material Cum Standard:</strong>
                                Brass
                            </li>
                            <li>
                                <strong>Product Description:</strong>
                                Two piece design ‘T’ Handle Operated
                            </li>
                            <li>
                                <strong>Type of Seat:</strong> Renewable PTFE
                                Seats
                            </li>

                            <li><strong>Port Opening:</strong> Full Bore</li>
                            <li>
                                <strong>End Details:</strong> Screwed Female BSP
                                Parallel Threads
                            </li>
                        </ul>
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">Applications</div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Connection & drinking water network intended
                                    for human consumption.
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <h5>Measurement</h5>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Size</th>
                                <th>Length</th>
                                <th>Height</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="m-td align-middle">15mm</td>
                                <td class="m-td">95mm</td>
                                <td class="m-td">65mm</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="product-imgage mt-1 text-center">
                    <img
                        width="400px"
                        src="assets/img/products/Products Vien Diagram/Meter Ball Valve/Meter Ball Valve.png"
                        alt="Images"
                    />
                </div>
            </div>

            <div class="notes mt-2">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to continual
                        design improvements. Please confirm before placing
                        order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

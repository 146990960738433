import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends AppComponentBase implements OnInit {
    classApplied = false;

    constructor(translateService: TranslateService) {
      super(translateService)
    }


    ngOnInit(): void {

    }
    toggleClass() {
        this.classApplied = !this.classApplied;
    }


}

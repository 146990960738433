import { TranslateService } from "@ngx-translate/core";
import { OwlOptions } from "ngx-owl-carousel-o";

export abstract class AppComponentBase {
    companyName = 'Priti Valve & Fittings Industries Pvt. Ltd.'
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        center: true,
        touchDrag: false,
        pullDrag: false,
        autoplay: true,
        autoplayHoverPause: true, // Stops autoplay on hover
        dots: true,
        navSpeed: 400,
        navText: ['<i class="fa-solid fa-arrow-left"></i>', '<i class="fa-solid fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: false
    };


    constructor(private translateService?: TranslateService) {
        translateService?.addLangs(['en', 'nep']);
        translateService?.setDefaultLang('en');
    }

    switchLanguage(language: string) {
        this.translateService.use(language);
    }

}

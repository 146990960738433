<!-- Inner Banner -->
<!-- <div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Style Two</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Services Style Two</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- Inner Banner End -->

<!-- Service Area -->
<div class="service-area-two pt-4 pb-70">
    <div class="container">
        <div class="tab project-tab text-center">
            <ul class="tabs">
                <li><a href="#">Valve and Fittings</a></li>
                <li><a href="#">Sanitary</a></li>
            </ul>

            <div class="tab_content current active">
                <div class="tabs_item current">
                    <div class="project-tab-item pt-4">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/brass-bib-tap"
                                            ><img
                                                src="assets/img/services/services-item2.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-analytics"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/brass-bib-tap"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/brass-bib-tap"
                                                >Bib Cock NS</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/gate-valve"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/gate-valve"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/gate-valve"
                                                >Gate Valve NS</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/globe-valve"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/globe-valve"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/globe-valve"
                                                >Globe Valve NS</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/ferulle-cock"
                                            ><img
                                                src="assets/img/services/services-item4.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-people"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/ferulle-cock"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/ferulle-cock"
                                                >Ferrule Cock NS</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            class="service-item-more cursor-pointer"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a class="cursor-pointer"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                >Brass Union</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/flow-regulating-valve"
                                            ><img
                                                src="assets/img/services/services-item3.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-sort"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/flow-regulating-valve"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/flow-regulating-valve"
                                                >Flow Regulating Valve</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/bptType-float-valve"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-briefcase-1"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/bptType-float-valve"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/bptType-float-valve"
                                                >BPT Type Float Valve</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            routerLink="/product-details/air-release-valve"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details/air-release-valve"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                routerLink="/product-details/air-release-valve"
                                                >Air Release Valve
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                    <span
                                        class="page-numbers current"
                                        aria-current="page"
                                        >1</span
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="page-numbers"
                                        >2</a
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="page-numbers"
                                        >3</a
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="next page-numbers"
                                        >Next
                                        <i class="bx bx-right-arrow-alt"></i
                                    ></a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row pt-45">
                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item2.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-analytics"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Angle Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item3.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-sort"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Short Body</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item4.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-people"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Long Body</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i class="flaticon-briefcase-1"></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Pillar Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Pillar Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Concealed Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details"
                                                >Sink Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a routerLink="/product-details"
                                            ><img
                                                src="assets/img/services/services-item5.jpg"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/services-2"
                                            class="service-item-icon"
                                            ><i
                                                class="flaticon-chess-pieces"
                                            ></i
                                        ></a>
                                        <a
                                            routerLink="/product-details"
                                            class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a routerLink="/product-details">
                                                Swan Neck Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                    <span
                                        class="page-numbers current"
                                        aria-current="page"
                                        >1</span
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="page-numbers"
                                        >2</a
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="page-numbers"
                                        >3</a
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="next page-numbers"
                                        >Next
                                        <i class="bx bx-right-arrow-alt"></i
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Service Area End -->

<!-- Modal -->
<div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content rounded-0 border-0">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Brass Union
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    #btnClose
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="service-area-two">
                    <div class="container">
                        <div class="tab project-tab text-center">
                            <div class="tab_content current active">
                                <div class="tabs_item current">
                                    <div class="project-tab-item pt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="service-item">
                                                    <div class="images">
                                                        <a
                                                            routerLink="/product-details/brass-union-ordinary"
                                                            ><img
                                                                src="assets/img/services/services-item5.jpg"
                                                                alt="Images"
                                                        /></a>
                                                        <a
                                                            routerLink="/services-2"
                                                            class="service-item-icon"
                                                            ><i
                                                                class="flaticon-chess-pieces"
                                                            ></i
                                                        ></a>
                                                        <a
                                                            routerLink="/product-details/brass-union-ordinary"
                                                            class="service-item-more"
                                                            ><i
                                                                class="flaticon-double-right-arrows-angles"
                                                            ></i
                                                        ></a>
                                                    </div>

                                                    <div class="content">
                                                        <h3>
                                                            <a
                                                                routerLink="/product-details/brass-union-ordinary'"
                                                                >Brass Union
                                                                Ordinary</a
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6">
                                                <div class="service-item">
                                                    <div class="images">
                                                        <a
                                                            routerLink="/product-details/brass-union-unicef-type"
                                                            ><img
                                                                src="assets/img/services/services-item5.jpg"
                                                                alt="Images"
                                                        /></a>
                                                        <a
                                                            routerLink="/services-2"
                                                            class="service-item-icon"
                                                            ><i
                                                                class="flaticon-chess-pieces"
                                                            ></i
                                                        ></a>
                                                        <a
                                                            routerLink="/product-details/brass-union-unicef-type"
                                                            class="service-item-more"
                                                            ><i
                                                                class="flaticon-double-right-arrows-angles"
                                                            ></i
                                                        ></a>
                                                    </div>

                                                    <div class="content">
                                                        <h3>
                                                            <a
                                                                routerLink="/product-details/brass-union-unicef-type'"
                                                                >Brass Union
                                                                UNICEF Type</a
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LOCALE_ID, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { BlogStyleTwoComponent } from './components/pages/blog-style-two/blog-style-two.component';
import { BlogStyleOneComponent } from './components/pages/blog-style-one/blog-style-one.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleOneComponent } from './components/pages/services-style-one/services-style-one.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { AppleNowComponent } from './components/pages/apple-now/apple-now.component';
import { TeamComponent } from './components/pages/team/team.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrassBibTapComponent } from './components/products/brass-bib-tap/brass-bib-tap.component';
import { GateValveComponent } from './components/products/gate-valve/gate-valve.component';
import { GlobeValveComponent } from './components/products/globe-valve/globe-valve.component';
import { FerulleCockComponent } from './components/products/ferulle-cock/ferulle-cock.component';
import { FlowRegulatingValveComponent } from './components/products/flow-regulating-valve/flow-regulating-valve.component';
import { BptTypeFloatValveComponent } from './components/products/bpt-type-float-valve/bpt-type-float-valve.component';
import { AirReleaseValveComponent } from './components/products/air-release-valve/air-release-valve.component';
import { BrassUnionOrdinaryComponent } from './components/products/Brass Union/brass-union-ordinary/brass-union-ordinary.component';
import { BrassUnionUnicefComponent } from './components/products/Brass Union/brass-union-unicef/brass-union-unicef.component';
import { ValveFittingsComponent } from './components/category/valve-fittings/valve-fittings.component';
import { SanitaryComponent } from './components/category/sanitary/sanitary.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NozzleCockComponent } from './components/products/nozzle-cock/nozzle-cock.component';
import { BrassBallValveComponent } from './components/products/brass-ball-valve/brass-ball-valve.component';
import { MeterBallValveComponent } from './components/products/meter-ball-valve/meter-ball-valve.component';
import { CustomTranslator } from './translator/translator';


import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';

export function translateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    BlogStyleTwoComponent,
    BlogStyleOneComponent,
    BlogDetailsComponent,
    ServicesDetailsComponent,
    ServicesStyleTwoComponent,
    ServicesStyleOneComponent,
    ComingSoonComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    SignUpComponent,
    SignInComponent,
    ErrorComponent,
    TestimonialsComponent,
    FaqComponent,
    CaseStudyComponent,
    ProjectsDetailsComponent,
    ProjectsComponent,
    AppleNowComponent,
    TeamComponent,
    AboutComponent,
    ContactComponent,
    BrassBibTapComponent,
    GateValveComponent,
    GlobeValveComponent,
    FerulleCockComponent,
    FlowRegulatingValveComponent,
    BptTypeFloatValveComponent,
    AirReleaseValveComponent,
    BrassUnionOrdinaryComponent,
    BrassUnionUnicefComponent,
    ValveFittingsComponent,
    SanitaryComponent,
    DownloadsComponent,
    NozzleCockComponent,
    BrassBallValveComponent,
    MeterBallValveComponent,
    WhatsappComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    PdfViewerModule,
    ReactiveFormsModule,FormsModule, ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateHttpLoaderFactory,
          deps: [HttpClient]
        }
      }) 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent extends AppComponentBase implements OnInit {
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: true,
        dots: true,
        navSpeed: 400,
        navText: ['<i class="fa-solid fa-arrow-left"></i>',
            '<i class="fa-solid fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: false
    }

    currentSlide = 0

    images = [
        { id: 1, src: "assets/img/pritivalve_slider.jpg", alt: 'img', title: 'NS Certified Company' },
        { id: 2, src: "assets/img/industry.png", alt: 'img', title: 'Serving to Wide Industrial Sector' },
        { id: 3, src: 'assets/img/darkpipe.png', alt: 'img', title: '' },
    ]

    clientImages = [
        {
            id: 1, src: 'assets/img/client/Unicef Nepal.jpeg', title: 'Unicef Nepal', desc: "UNICEF Nepal's Parenting site. Assisting parents in giving kids the finest possible start in life."
        },
        {
            id: 2, src: 'assets/img/client/Helvetas Nepal.png', title: 'Helvetas Surkhet', desc: "Helvetas Nepal fosters sustainable development and poverty reduction by empowering people with knowledge and skills to improve their livelihoods."
        },
        {
            id: 3, src: 'assets/img/client/British Gurkhas Welfare Trust.jpeg', title: 'British Gurkhas Welfare Trust-Pokhara', desc: "The Gurkha Welfare Trust is the primary welfare organization for Gurkhas, providing free medical care to its pensioners."
        },
        {
            id: 4, src: 'assets/img/client/plan international.png', title: 'Plan International', desc: "Plan International promotes children's rights and girls' equality in 75+ countries across Asia, Africa, and the Americas."
        },
        {
            id: 5, src: 'assets/img/client/sappros.png', title: 'Sapros Nepal', desc: "SAPPROS Nepal is a recognized nonprofit NGO that aims to empower small farmers and underprivileged communities for a better socioeconomic life."
        },
        {
            id: 6, src: 'assets/img/client/newah.jpg', title: 'Newah', desc: "Our Nepali NGO is dedicated to improving people's quality of life through WASH services, prioritizing rural and municipal areas. We strive for universal access to WASH without discrimination."
        },
        {
            id: 7, src: 'assets/img/client/Unicef Nepal.jpeg', title: 'Unicef Bhutan', desc: "UNICEF Bhutan promotes child rights, education, health, and protection in the country as part of the global United Nations International Children's Emergency Fund."
        },
        {
            id: 8, src: 'assets/img/client/wateraid.png', title: 'Water Aid', desc: "Water Aid mission is to provide sustainable clean water, decent toilets, and hygiene education to save lives."
        }
        , {
            id: 9, src: 'assets/img/client/usaid.png', title: 'USAID Karnali Water', desc: "USAID Karnali Water supports Nepal's water security and healthy communities through sustainable water access, management and conservation"
        }
    ]


    constructor(private title: Title, translateService: TranslateService) { super(translateService) }

    ngOnInit(): void {
        this.title.setTitle(`Home | ${this.companyName}`)
    }

    onSlideChanged(event: any) {
        this.currentSlide = event.startPosition + 1;
    }

    getSlideTitleClass(slideId: number, direction?: string) {
        if (slideId === 1 && direction === 'right') {
            return `slide-title-right ${slideId === this.currentSlide ? 'tracking-in-expand-fwd-top' : ''}`;
        }
        return `slide-title ${slideId === this.currentSlide ? 'tracking-in-expand-fwd-bottom' : ''}`;
    }

}

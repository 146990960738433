import { Component } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-air-release-valve',
  templateUrl: './air-release-valve.component.html',
  styleUrls: ['./air-release-valve.component.scss']
})
export class AirReleaseValveComponent extends AppComponentBase {
    constructor(private title:Title){
        super();
        this.title.setTitle(`Gunmetal Air Release Valve | ${this.companyName}`)

    }

}

<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class="bx bxs-location-plus"></i>
                                </div>
                                <a>{{ "address" | translate }}</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class="bx bxs-envelope"></i>
                                </div>
                                <a href="mailto:info@pritivalve.com"
                                    >info@pritivalve.com</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li>
                                <a
                                    href="https://www.facebook.com/people/Priti-Valve-Fittings-Industries-Pvt-Ltd/100039928732991/"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>

                            <!-- <li>
                                <a href="#" target="_blank"
                                    ><i class="fa-brands fa-linkedin"></i
                                ></a>
                            </li> -->
                        </ul>
                    </div>

                    <div class="language d-flex align-items-center gap-3">
                        <i class="bx bxs-phone language-icon"></i>
                        <span class="text-white">+977 25 533701</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav
            class="navbar navbar-expand-lg navbar-light bg-light"
            [class.active]="classApplied"
        >
            <a routerLink="/" title="Priti Valve & Fitting Industries Pvt. Ltd."
                ><img
                    src="assets/img/Priti valve logo.png"
                    width="137px"
                    alt="Logo"
            /></a>
            <button
                class="navbar-toggler"
                type="button"
                (click)="toggleClass()"
            >
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item">
                        <a
                            href="javascript:void(0)"
                            class="nav-link dropdown-toggle"
                            routerLink="/"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Home
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            routerLink="/about"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >About Us</a
                        >
                    </li>

                    <li class="nav-item">
                        <a
                            href="javascript:void(0)"
                            class="nav-link dropdown-toggle"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Products <i class="bx bx-chevron-down"></i>
                        </a>

                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/products/valve-fittings"
                                    class="nav-link dropdown-toggle"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Valve & Fittings
                                    <i class="bx bx-chevron-right"></i
                                ></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/brass-bib-tap"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Brass Bib Cock NS 362</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/gate-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Gunmetal Gate Valve NS 149</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/globe-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Gunmetal Globe Valve NS 382</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/ferulle-cock"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Gunmetal Ferrule Cock NS 361</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            class="nav-link dropdown-toggle cursor-pointer"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Brass Union
                                            <i class="bx bx-chevron-right"></i
                                        ></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/product-details/brass-union-ordinary"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Brass Union Local Type</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    routerLink="/product-details/brass-union-unicef-type"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Brass Union UNF Type</a
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/flow-regulating-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Flow Control Valve</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/bptType-float-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Float Valve BPT Type</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/air-release-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Gunmetal Air Release Valve</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/nozzle-cock"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Brass Nozzle Cock</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/brass-ball-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Brass Ball Valve Butterfly Type</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/product-details/meter-ball-valve"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Brass Meter Ball Valve</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/products/sanitary"
                                    class="nav-link dropdown-toggle"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Sanitary <i class="bx bx-chevron-right"></i
                                ></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Angle Cock</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Short Body</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Long Body</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Pillar Cock</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Concealed Cock</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Sink Cock</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >CP Swan Neck</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a
                            routerLink="/downloads"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Downloads</a
                        >
                    </li>

                    <li class="nav-item">
                        <a
                            routerLink="/contact"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Contact</a
                        >
                    </li>
                </ul>

                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a
                            routerLink="/"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            (click)="toggleClass()"
                            >Home</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            routerLink="/about"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            (click)="toggleClass()"
                            >About Us</a
                        >
                    </li>

                    <li class="nav-item">
                        <a
                            href="javascript:void(0)"
                            class="nav-link dropdown-toggle"
                            >Products <i class="bx bx-chevron-down"></i>
                        </a>

                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/products/valve-fittings"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    (click)="toggleClass()"
                                    >Valve & Fittings</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/products/sanitary"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    (click)="toggleClass()"
                                    >Sanitary</a
                                >
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a
                            routerLink="/downloads"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            (click)="toggleClass()"
                            >Downloads</a
                        >
                    </li>

                    <li class="nav-item">
                        <a
                            routerLink="/contact"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            (click)="toggleClass()"
                            >Contact</a
                        >
                    </li>
                </ul>

                <!-- <div class="others-options d-flex align-items-center">
                    <div class="option-item">
                        <i class="search-btn bx bx-search"></i>
                        <i class="close-btn bx bx-x"></i>
                        <div class="search-overlay search-popup">
                            <div class="search-box">
                                <form class="search-form">
                                    <input
                                        class="search-input"
                                        name="search"
                                        placeholder="Search"
                                        type="text"
                                    />
                                    <button class="search-button" type="submit">
                                        <i class="bx bx-search"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="option-item">
                        <div class="menu-icon d-in-line">
                            <span class="burger-menu menu-icon-two"
                                ><i class="flaticon-menu"></i
                            ></span>
                        </div>
                    </div>
                </div> -->
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Brass Union UNF Type
                </h4>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/BRASS UNION UNF TYPE.pdf"  class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img
                                    style="margin-left: 95px"
                                    [src]="slide.src"
                                    [alt]="slide.alt"
                                />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">
                            Specification for Cast Brass Union UNF Type For
                            Water Services
                        </div>
                    </div>

                    <ul class="list-unstyled">
                        <li>
                            <strong>Scope:</strong> Normally Brass Unions are
                            used for connecting GI Pipes to HDP Pipes, There is
                            no need of <strong>HDP</strong> Adoptors to
                            join/connect HDP Pipe because the clamp
                            automatically holds the pipe strongly even at higher
                            working pressure.
                        </li>
                        <li>
                            <strong>Nominal Size:</strong> 1/2&quot;x20 MM,
                            3/4&quot; x25 MM, 1”x32 MM, 1.25&quot;x40 MM,
                            1.5&quot;x50 MM, 2&quot;x63 MM..
                        </li>

                        <li>
                            <strong>Components:</strong> Body, Nut, Clamp, Metal
                            Washer &amp; Rubber O Rings.
                        </li>

                        <li>
                            <strong>Materials:</strong> Cast Brass Metal is used
                            for Body, Nut, Clamp&amp; Metal Washer. Where as
                            Natural Rubber for O Rings.
                        </li>
                        <li>
                            <strong>Finish:</strong> Outside surface is to be
                            Rough(as castes) and Bright finish from inside.
                        </li>

                        <li>
                            <strong>Testing:</strong> Every Brass Union,
                            complete assembled with its components are tested
                            under an internal hydrostatic pressure of 10 kg
                            f/cm<sup>2</sup>.
                        </li>

                        <li>
                            <strong>Marking:</strong> PRITI (Trade Mark), Size,
                            Made in Nepal.
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>

        <h4>
            Dimensions: Dimensions and Tolerance of Brass Union (All
            measurements in MM)
        </h4>
        <hr />
        <div class="row table-one dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Part 1 Nut</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Size of Brass Unions</th>
                                    <th>1/2&quot;x20</th>
                                    <th>3/4&quot;x25</th>
                                    <th>1&quot;x32</th>
                                    <th>11/4&quot;x40</th>
                                    <th>1.5&quot;x50</th>
                                    <th>2&quot;x65</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Bore Dia to inlet HDP Pipe, (A)</td>
                                    <td>20.5</td>
                                    <td>25.5</td>
                                    <td>32.5</td>
                                    <td>40.5</td>
                                    <td>50.5</td>
                                    <td>65.5</td>
                                </tr>

                                <tr>
                                    <td>Width of Hexagonal ends, (B)</td>
                                    <td>6.0</td>
                                    <td>6.0</td>
                                    <td>8.0</td>
                                    <td>8.0</td>
                                    <td>10.0</td>
                                    <td>10.0</td>
                                </tr>

                                <tr>
                                    <td>Length of thread, (C)</td>
                                    <td>8</td>
                                    <td>8</td>
                                    <td>9</td>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row table-two dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Part 2 Clamp</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Size of Brass Unions</th>
                                    <th>1/2&quot;x20</th>
                                    <th>3/4&quot;x25</th>
                                    <th>1&quot;x32</th>
                                    <th>11/4&quot;x40</th>
                                    <th>1.5&quot;x50</th>
                                    <th>2&quot;x65</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Min Width, (D)</td>
                                    <td>8</td>
                                    <td>8</td>
                                    <td>9</td>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Clamps to be suitable to clamp HDP Pipes.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row table-three dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Part 3 Metal Flat Ring</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Size of Brass Unions</th>
                                    <th>1/2&quot;x20</th>
                                    <th>3/4&quot;x25</th>
                                    <th>1&quot;x32</th>
                                    <th>11/4&quot;x40</th>
                                    <th>1.5&quot;x50</th>
                                    <th>2&quot;x65</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Min. Thickness, (E)</td>
                                    <td>2.5</td>
                                    <td>2.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                </tr>

                                <tr>
                                    <td>Min. Inside Dia</td>
                                    <td>20.5</td>
                                    <td>25.5</td>
                                    <td>32.5</td>
                                    <td>40.5</td>
                                    <td>50.5</td>
                                    <td>65.5</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row table-four dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Part 4 Rubber &quot;0&quot; Ring</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Size of Brass Unions</th>
                                    <th>1/2&quot;x20</th>
                                    <th>3/4&quot;x25</th>
                                    <th>1&quot;x32</th>
                                    <th>11/4&quot;x40</th>
                                    <th>1.5&quot;x50</th>
                                    <th>2&quot;x65</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Min. Thickness Dia. (H)</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                </tr>

                                <tr>
                                    <td>Min. Inside Dia of ring, (J)</td>
                                    <td>20</td>
                                    <td>25</td>
                                    <td>32</td>
                                    <td>40</td>
                                    <td>50</td>
                                    <td>65</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row table-five dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Part 5 Body</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Size of Brass Unions</th>
                                    <th>1/2&quot;x20</th>
                                    <th>3/4&quot;x25</th>
                                    <th>1&quot;x32</th>
                                    <th>11/4&quot;x40</th>
                                    <th>1.5&quot;x50</th>
                                    <th>2&quot;x65</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Min. Length of thread (for nut), (K)
                                    </td>
                                    <td>8.5</td>
                                    <td>8.5</td>
                                    <td>9.5</td>
                                    <td>9.5</td>
                                    <td>10.5</td>
                                    <td>10.5</td>
                                </tr>

                                <tr>
                                    <td>Min. Wall Thickness</td>
                                    <td>2.5</td>
                                    <td>2.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                    <td>3.5</td>
                                </tr>

                                <tr>
                                    <td>
                                        WI. Length of thread (GI to connect),
                                        (L)
                                    </td>
                                    <td>11</td>
                                    <td>11</td>
                                    <td>12</td>
                                    <td>12</td>
                                    <td>13</td>
                                    <td>13</td>
                                </tr>

                                <tr>
                                    <td>Face to face</td>
                                    <td>39</td>
                                    <td>37</td>
                                    <td>46</td>
                                    <td>46</td>
                                    <td>51</td>
                                    <td>53</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row table-six dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Mass in grams</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Size of Brass Unions</th>
                                    <th>1/2&quot;x20</th>
                                    <th>3/4&quot;x25</th>
                                    <th>1&quot;x32</th>
                                    <th>11/4&quot;x40</th>
                                    <th>1.5&quot;x50</th>
                                    <th>2&quot;x65</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Min. weight of complete assembled set
                                    </td>
                                    <td>160</td>
                                    <td>220</td>
                                    <td>350</td>
                                    <td>450</td>
                                    <td>700</td>
                                    <td>850</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-imgage mt-1 text-center">
            <img
                width="300px"
                src="assets/img/products/Products Vien Diagram/Brass Union Local Type/Brass Union Local Type-1.png"
                alt="Images"
            />
        </div>
        <div class="notes mt-2">
            <h5>Notes</h5>
            <ol>
                <li>
                    Product images are for illustration purposes only. Actual
                    product may vary.
                </li>
                <li>
                    Actual dimensions may change over time, due to continual
                    design improvements. Please confirm before placing order.
                </li>
            </ol>
        </div>
    </div>
</div>

<!-- Inner Banner -->
<!-- <div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Projects Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Projects Details</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- Inner Banner End -->

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <h4 class="product-name">IS-5 Bronze Gate Valve</h4>
        <div>Non-Rising Stem, Screwed, IS : 778 Class-2 (ISI Marked)</div>
        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <img
                        src="https://www.santvalves.com/wp-content/uploads/SANT_GunMetalGateValve_IS5-900x900.jpg"
                        alt="Images"
                    />
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">
                            Product Description
                        </div>
                        <div class="product-code">HSN:89999</div>
                    </div>

                    <ul class="list-unstyled">
                        <li>Product: ‘SANT’ Bronze Gate Valve</li>
                        <li>Product Standard : IS-778</li>
                        <li>Pressure/Class/Rating : Class-2</li>
                        <li>
                            Body Material cum Standard : GM ( IS:318 Gr.LTB2 )
                        </li>
                        <li>
                            Description : Screwed in Bonnet, Inside Screw,
                            Non-Rising Stem
                        </li>
                        <li>Type of Seat : Integral sea</li>
                        <li>Type of Wedge : Solid wedge</li>
                        <li>Trim : Cu-Alloy</li>
                        <li>Gland Packing : PTFE</li>
                        <li>
                            End Details : Screwed female threads to IS:554
                            Parallel
                        </li>
                    </ul>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <ul class="list-unstyled">
                        <li>Body Test Pressure : 2.4 MPa Hyd.</li>
                        <li>Seat & Back Seat Test Pressure : 1.6 MPa Hyd.</li>
                        <li>Max Working Temperature: 45°C</li>
                        <li>
                            Body Material cum Standard : GM ( IS:318 Gr.LTB2 )
                        </li>
                        <li>
                            Our Works’ Test Certificate is furnished with every
                            supply.
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials mt-5">
            <h5>Materials</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                            <th>Specifications</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Body, Bonnet, Wedge, Stuffing Box, Gland & Gland
                                Nut
                            </td>
                            <td>Leaded Tin Bronze</td>
                            <td>IS:318 Gr.LTB2</td>
                        </tr>
                        <tr>
                            <td>
                                Body, Bonnet, Wedge, Stuffing Box, Gland & Gland
                                Nut
                            </td>
                            <td>Leaded Tin Bronze</td>
                            <td>IS:318 Gr.LTB2</td>
                        </tr>
                        <tr>
                            <td>
                                Body, Bonnet, Wedge, Stuffing Box, Gland & Gland
                                Nut
                            </td>
                            <td>Leaded Tin Bronze</td>
                            <td>IS:318 Gr.LTB2</td>
                        </tr>
                        <tr>
                            <td>
                                Body, Bonnet, Wedge, Stuffing Box, Gland & Gland
                                Nut
                            </td>
                            <td>Leaded Tin Bronze</td>
                            <td>IS:318 Gr.LTB2</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions mt-5">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8"
            >
                <div>
                    <h5>Dimensions (in mm)</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nominal Size</th>
                                    <th>Nominal Size (Inches)</th>
                                    <th>F</th>
                                    <th>H (Approx.)</th>
                                    <th>∅W (Approx.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>15</td>
                                    <td>1/2</td>
                                    <td>60±1.5</td>
                                    <td>105</td>
                                    <td>60</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>1/2</td>
                                    <td>60±1.5</td>
                                    <td>105</td>
                                    <td>60</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>1/2</td>
                                    <td>60±1.5</td>
                                    <td>105</td>
                                    <td>60</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>1/2</td>
                                    <td>60±1.5</td>
                                    <td>105</td>
                                    <td>60</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="notes mt-5">
                    <h5>Notes</h5>
                    <ol>
                        <li>
                            Product images are for illustration purposes only.
                            Actual product may vary.
                        </li>
                        <li>
                            Actual dimensions may change over time, due to
                            continual design improvements. Please confirm before
                            placing order.
                        </li>
                    </ol>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4"
            >
                <div class="product-img">
                    <img
                        src="https://www.santvalves.com/wp-content/uploads/SANT_IS-5-900x900.jpg"
                        alt="Images"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Project Area -->
<!-- <div class="project-area pb-70">
    <div class="container">
        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Features</a></li>
                <li><a href="#">Spealization</a></li>
            </ul>

            <div class="tab_content current active">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <ol>
                            <li>a</li>
                            <li>b</li>
                            <li>c</li>
                        </ol>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <ol>
                            <li>d</li>
                            <li>e</li>
                            <li>f</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Project Area End -->

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-meter-ball-valve',
    templateUrl: './meter-ball-valve.component.html',
    styleUrls: ['./meter-ball-valve.component.scss']
})
export class MeterBallValveComponent extends AppComponentBase {
    constructor(private title: Title) {
        super();
        this.title.setTitle(`Brass Meter Ball Valve | ${this.companyName}`)
    }


    images = [
        { id: 1, src: "assets/img/products/Brass Meter Ball Valve one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Brass Meter Ball Valve two.png", alt: 'img' },
        { id: 3, src: 'assets/img/products/Brass Meter Ball Valve three.png', alt: 'img' }
    ]
}

import { Component } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-sanitary',
  templateUrl: './sanitary.component.html',
  styleUrls: ['./sanitary.component.scss']
})
export class SanitaryComponent extends AppComponentBase {
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: true,
        dots: false,
        navSpeed: 400,
        navText: ['<i class="fa-solid fa-arrow-left"></i>',
            '<i class="fa-solid fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }
    constructor(private title:Title){
        super()
        this.title.setTitle(`Sanitary | ${this.companyName}`)
    }

}

<!-- Footer Area -->
<footer class="footer-area py-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"
                            ><img
                                src="assets/img/Priti valve logo.png"
                                width="200px"
                                alt="Logo"
                        /></a>
                    </div>
                    <p>
                        Priti Valve & Fitting Industries is the manufacturer of
                        water fittings and Brass/Metal Valve in Nepal. It has
                        experience of more than 3 decades in this business & has
                        completed various governmental & private sectors
                        projects, ensuring high performance action for all
                        domestic & industrial use.
                    </p>
                    <ul class="social-link">
                        <li>
                            <a
                                href="https://www.facebook.com/people/Priti-Valve-Fittings-Industries-Pvt-Ltd/100039928732991/"
                                target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <!--
                        <li>
                            <a href="#"
                                ><i class="fa-brands fa-linkedin"></i></a>
                        </li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/downloads">Downloads</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li>
                            <a routerLink="/products/valve-fittings"
                                >Valve & Fittings</a
                            >
                        </li>
                        <li><a routerLink="/products/sanitary">Sanitary</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class="bx bx-time"></i>
                            Sun - Fri: 8.00am - 6.00pm
                        </li>
                        <li>
                            <i class="bx bx-location-plus"></i>
                            Dharan Industrial Estate, Dharan 8, Sunsari, Koshi
                            Pradesh, Nepal 56700
                        </li>
                        <li>
                            <i class="bx bx-phone"></i>
                            <a href="tel:+61283171380">+977 25 533701 </a>
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:info@pritivalve.com"
                                >info@pritivalve.com</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>© Copyright Priti Valve & Fitting Industries Pvt.Ltd | 2023</p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->

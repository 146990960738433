import { Component } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-ferulle-cock',
    templateUrl: './ferulle-cock.component.html',
    styleUrls: ['./ferulle-cock.component.scss']
})
export class FerulleCockComponent extends AppComponentBase {

    constructor(private title: Title) {
        super();
        this.title.setTitle(`Gunmetal Ferrule Cock NS 361 | ${this.companyName}`)
    }

    images = [
        { id: 1, src: "assets/img/products/Gunmetal Ferrule Cock NS 381 one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Gunmetal Ferrule Cock NS 381 two.png", alt: 'img' },
    ]
}

import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-valve-fittings',
  templateUrl: './valve-fittings.component.html',
  styleUrls: ['./valve-fittings.component.scss']
})
export class ValveFittingsComponent extends AppComponentBase implements OnDestroy {
    @ViewChild('btnClose') btnCloseRef: ElementRef<HTMLDivElement>
    constructor(private title:Title) {
        super()
        this.title.setTitle(`Valve & Fittings | ${this.companyName}`)

    }


    ngOnDestroy(): void {
        this.btnCloseRef.nativeElement.click()
    }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import emailjs from 'emailjs-com';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends AppComponentBase implements OnInit {
    EmailJSConfig = {
        USER_ID: 'Np1nls2DyLsnVPlz5',
        SERVICE_ID: 'service_ffdyebg',
        TEMPLATE_ID: 'template_hoiruqr'
    };
    contactForm: FormGroup

    constructor(private toast: ToastrService, private title: Title) {
        super()
        ; this.title.setTitle(`Contact | ${this.companyName}`)
    }

    ngOnInit(): void {
        this.createContactForm()
    }

    createContactForm() {
        this.contactForm = new FormGroup({
            name: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required),
            phone: new FormControl('', Validators.required),
            subject: new FormControl('', Validators.required),
            message: new FormControl('', Validators.required)
        })
    }

    sendEmail() {
        if (this.contactForm.valid) {
            const params = {
                to_name: 'Saajan Shrestha',
                from_name: this.contactForm.value.name,
                email: this.contactForm.value.email,
                message: this.contactForm.value.message
            };

            emailjs.send(
                this.EmailJSConfig.SERVICE_ID,
                this.EmailJSConfig.TEMPLATE_ID,
                params,
                this.EmailJSConfig.USER_ID
            ).then(() => {
                this.toast.success("Email Sent Successfully!")
            }, (error) => {
                this.toast.error("Your daily limit reached out!")
            });
        }

    }

}

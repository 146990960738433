<!-- Inner Banner -->
<!-- <div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">

            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- Inner Banner End -->

<!-- choose Area -->
<div class="choose-area-two choose-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 d-none d-md-none d-lg-block">
                <div class="choose-img-2">
                    <img src="assets/img/about/companyimg.jpeg" alt="Images" />

                    <div class="choose-dots">
                        <img
                            src="assets/img/choose/choose-dots.png"
                            alt="Images"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2><span>Company Profile</span></h2>
                    <div class="choose-img-2 mb-3 d-lg-none d-md-block">
                        <img src="assets/img/about/companyimg.jpeg" alt="Images" />
    
                        <div class="choose-dots">
                            <img
                                src="assets/img/choose/choose-dots.png"
                                alt="Images"
                            />
                        </div>
                    </div>
                    <p>
                        Priti Valve was established in 1985 under the dynamic
                        leadership of our chairman Mr. Ashok Kumar Agrawal and
                        family. Under the management of our chairman, Pritivalve
                        now has gained a reputation in Nepal's market by
                        prioritizing quality, continuously investing in
                        technology, and consistently prioritizing the needs of
                        its clients. By nurturing existing relationships and
                        building new ones, Priti Valve has experienced
                        significant growth over the years. In 1988, the company
                        had a modest manufacturing capacity of 30 metric tons
                        per year. However, with the recent expansion of its
                        plant in 2019, the company now has an installed capacity
                        of 250 metric tons, allowing it to produce 120 tonnes of
                        high-quality water fittings annually.
                    </p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon flaticon-team"></i>
                                <div class="content">
                                    <h3>38+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon flaticon-people"></i>
                                <div class="content">
                                    <h3>100+</h3>
                                    <span>Passionate Employee</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon flaticon-analytics"></i>
                                <div class="content">
                                    <h3>100+</h3>
                                    <span>Projects & Ongoing</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/contact" class="get-btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images" />
    </div>
</div>
<!-- About Area End -->

<!-- about Area -->
<div class="about-area pt-4 pb-70">
    <div class="container">
        <div class="row">

         


            <div class="col-lg-7 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <h2>About Us</h2>

                        <div class="col-lg-5 col-md-12 d-block d-md-block d-lg-none mt-n120px">
                            <div class="about-img about-img-before">
                                <img src="assets/img/about/about.svg" alt="Images" />
                            </div>
                        </div>
                        <p>
                            Priti Valve is a leading manufacturer of GunMetal
                            valves and other various kinds of Brass/G.M. Water
                            fittings. We have been contributing to the nation
                            since BS 2042 in the field of water supply systems.
                            We are located in Dharan Industrial Estate and are
                            the first unit in Nepal to acquire the license to
                            use the quality certification mark “NS” on our
                            products. Our factory is well-equipped with a
                            laboratory that enables us to carry out all relevant
                            tests on our products, ensuring our commitment to
                            quality control.<br /><br />
                            Our products are made using the best quality Gun
                            Metal Ingots, LG-2 Grade, and Brass Honey Grade
                            imported from European countries. Our quality is
                            regularly inspected by Nepal Standard Beuro and
                            approved by Crown Agents (Surveyor Agency). We have
                            worked with reputable clients, including Unicef
                            Nepal, Helvetas Surkhet, British Gurkhas Welfare
                            Trust-Pokhara, Plan International, Sapros Nepal, and
                            Newah. We have also exported our products to Bhutan
                            Govt. and Unicef Bhutan.<br /><br />Our objective at
                            Priti Valve is to produce high-quality fittings
                            every time and at all times. Customer satisfaction
                            and safety are our main principles. We have been
                            supplying GM/Brass water fittings since 1998 with
                            complete satisfaction. Our products play a vital
                            role in the field of water supply and distribution
                            systems, and we believe that the quality of GM/Brass
                            fittings should be of high quality to prevent
                            leakage of valuable water.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12 d-none d-md-none d-lg-block">
                <div class="about-img about-img-before">
                    <img src="assets/img/about/about.svg" alt="Images" />
                </div>
            </div>
        </div>
    </div>
</div>

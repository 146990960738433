import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';

import { HomeOneComponent } from './components/pages/home-one/home-one.component';

import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';

import { BrassBibTapComponent } from './components/products/brass-bib-tap/brass-bib-tap.component';
import { GateValveComponent } from './components/products/gate-valve/gate-valve.component';
import { GlobeValveComponent } from './components/products/globe-valve/globe-valve.component';
import { FerulleCockComponent } from './components/products/ferulle-cock/ferulle-cock.component';
import { FlowRegulatingValveComponent } from './components/products/flow-regulating-valve/flow-regulating-valve.component';
import { BptTypeFloatValveComponent } from './components/products/bpt-type-float-valve/bpt-type-float-valve.component';
import { AirReleaseValveComponent } from './components/products/air-release-valve/air-release-valve.component';
import { BrassUnionOrdinaryComponent } from './components/products/Brass Union/brass-union-ordinary/brass-union-ordinary.component';
import { BrassUnionUnicefComponent } from './components/products/Brass Union/brass-union-unicef/brass-union-unicef.component';
import { ValveFittingsComponent } from './components/category/valve-fittings/valve-fittings.component';
import { SanitaryComponent } from './components/category/sanitary/sanitary.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { NozzleCockComponent } from './components/products/nozzle-cock/nozzle-cock.component';
import { BrassBallValveComponent } from './components/products/brass-ball-valve/brass-ball-valve.component';
import { MeterBallValveComponent } from './components/products/meter-ball-valve/meter-ball-valve.component';


const routes: Routes = [
    { path: '', component: HomeOneComponent },
    // { path: 'home-two', component: HomeTwoComponent },
    // { path: 'home-three', component: HomeThreeComponent },
    { path: 'about', component: AboutComponent },
    // { path: 'team', component: TeamComponent },
    // { path: 'apply-now', component: AppleNowComponent },
    // { path: 'projects', component: ProjectsComponent },
    {
        path: 'product-details', component: ProjectsDetailsComponent
    },
    { path: 'product-details/brass-bib-tap', component: BrassBibTapComponent },
    { path: 'product-details/gate-valve', component: GateValveComponent },
    { path: 'product-details/globe-valve', component: GlobeValveComponent },
    { path: 'product-details/ferulle-cock', component: FerulleCockComponent },
    { path: 'product-details/flow-regulating-valve', component: FlowRegulatingValveComponent },
    { path: 'product-details/bptType-float-valve', component: BptTypeFloatValveComponent },
    { path: 'product-details/air-release-valve', component: AirReleaseValveComponent },
    { path: 'product-details/brass-union-ordinary', component: BrassUnionOrdinaryComponent },
    { path: 'product-details/brass-union-unicef-type', component: BrassUnionUnicefComponent },
    { path: 'product-details/nozzle-cock', component: NozzleCockComponent },
    { path: 'product-details/brass-ball-valve', component: BrassBallValveComponent },
    { path: 'product-details/meter-ball-valve', component: MeterBallValveComponent },

    // { path: 'case-study', component: CaseStudyComponent },
    // { path: 'faq', component: FaqComponent },
    // { path: 'testimonials', component: TestimonialsComponent },
    // { path: 'error', component: ErrorComponent },
    // { path: 'sign-in', component: SignInComponent },
    // { path: 'sign-up', component: SignUpComponent },
    // { path: 'terms-conditions', component: TermsConditionsComponent },
    // { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    // { path: 'services-1', component: ServicesStyleOneComponent },
    { path: 'products/valve-fittings', component: ValveFittingsComponent },
    { path: 'products/sanitary', component: SanitaryComponent },
    // { path: 'services-details', component: ServicesDetailsComponent },
    // { path: 'blog-1', component: BlogStyleOneComponent },
    // { path: 'blog-2', component: BlogStyleTwoComponent },
    // { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'downloads', component: DownloadsComponent },


    { path: '**', component: ErrorComponent } // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }

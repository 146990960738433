<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Gunmetal Air Release Valve
                </h4>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/GUNMETAL AIR RELEASE VALVE.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-center"
            >
                <div class="product-img">
                    <img
                        src="assets/img/products/Gunmetal Air Release Valve.png"
                        alt="Images"
                    />
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">
                            Specification For Air Release Valve For Water
                            Services
                        </div>
                    </div>

                    <ul class="list-unstyled">
                        <li>
                            <strong>Scope:</strong> Air Release Valves are used
                            in Water Supply Systems to release unnecessary air,
                            from Pipe lines.
                        </li>
                        <li>
                            <strong>Materials:</strong> Materials used for the
                            manufacture of Air Release Valves: Leaded Tin bronze
                            for the Body, Copper sheet for the Ball &amp; Cast
                            Brass for the Nut &amp; Ball Pin.
                        </li>
                        <li>
                            <strong>Dimension &amp; Pressure:</strong>

                            <ul class="ul-list">
                                <li>
                                    Minimum wall thickness is not less than 2
                                    MM.
                                </li>
                                <li>
                                    Overall length is 114 MM &amp; outside Dia
                                    80 MM.
                                </li>
                                <li>
                                    Our Minimum working pressure in 6 kg
                                    f/cm<sup>2</sup>.
                                </li>
                                <li>
                                    Air Valve are constructed to withstand
                                    pressure up to 22 kg f/cm<sup>2</sup> .
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Pressure Test:</strong> Every Air Release
                            Valve Complete Assembled with its components are
                            tested under an internal hydrostatic pressure of 6
                            kg f/cm<sup>2</sup>
                        </li>
                        <li>
                            <strong>Markings:</strong> PRITI, SIZE &amp; Made in
                            Nepal.
                        </li>
                        <li>
                            <strong>Components:</strong> Body, Copper Ball,
                            Steel Ball, Ball Pin, Nut &amp; Washer.
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>

        <div class="product-image mt-1 text-center">
            <img
                width="600px"
                src="assets/img/products/Products Vien Diagram/Gunmetal Air Release Valve/Gunmetal Air Release Valve-1.jpg"
                alt="Images"
            />
        </div>

        <div class="notes mt-3">
            <h5>Notes</h5>
            <ol>
                <li>
                    Product images are for illustration purposes only. Actual
                    product may vary.
                </li>
                <li>
                    Actual dimensions may change over time, due to continual
                    design improvements. Please confirm before placing order.
                </li>
            </ol>
        </div>
    </div>
</div>

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-services-style-two',
    templateUrl: './services-style-two.component.html',
    styleUrls: ['./services-style-two.component.scss']
})
export class ServicesStyleTwoComponent implements OnInit, OnDestroy {
    @ViewChild('btnClose') btnCloseRef: ElementRef<HTMLDivElement>
    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.btnCloseRef.nativeElement.click()
    }

}

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div class="about-content d-flex justify-content-between flex-wrap align-items-baseline">
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Gunmetal Globe Valve NS 382
                </h4>
                <div>NS -382/054, NS MARKED</div>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/GUNMETAL GLOBE VALVE NS 382.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img style="margin-left: 95px;" [src]="slide.src" [alt]="slide.alt" />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">Specification</div>
                    </div>

                    <div class="py-3 pl-14px">
                        &#39;PRITI&#39; Gun Metal Globe Valve, screwed In
                        bonnet, inside screw, rising stem, `integral seats:
                        handwheel operated with backseat arrangement screwed
                        female ends (BSP): conforming to NS-382/054.
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Body Test Pressure = 22 kg f/cm<sup>2</sup>
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Seat &amp; Backseat Test Pressure = 17.5 kg
                                    f/cm<sup>2</sup>
                                </li>
                            </ul>
                        </div>
                        <p class="mt-3">
                            Our Works Test Certificate is furnished with every
                            supply.
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials">
            <h5>Materials</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                            <th>Material Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="m-th">
                                Body, Bonnet, Valve Disc, Checknut, Gland, Gland
                                Nut
                            </td>
                            <td class="m-th">Gun Metal/Leaded Tin Bronzes</td>
                            <td class="m-th">GM/LTB2</td>
                        </tr>

                        <tr>
                            <td class="m-th">Spindle</td>
                            <td class="m-th">Forged Brass</td>
                            <td class="m-th">FB</td>
                        </tr>

                        <tr>
                            <td class="m-th">Handwheel</td>
                            <td class="m-th">Cast Iron</td>
                            <td class="m-th">CI</td>
                        </tr>
                        <tr>
                            <td class="m-th">Gland Packing</td>
                            <td class="m-th">Asbestos</td>
                            <td class="m-th">ASB</td>
                        </tr>
                        <tr>
                            <td class="m-th">
                                Screw &amp; Washer for Handwheel
                            </td>
                            <td class="m-th">Mild Steel</td>
                            <td class="m-th">Ms</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Dimensions (in mm)</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nominal Size</th>
                                    <th>F</th>
                                    <th>H</th>
                                    <th>W</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="d-th">8</td>
                                    <td class="d-th">47</td>
                                    <td class="d-th">75</td>
                                    <td class="d-th">44</td>
                                </tr>

                                <tr>
                                    <td class="d-th">10</td>
                                    <td class="d-th">50</td>
                                    <td class="d-th">80</td>
                                    <td class="d-th">44</td>
                                </tr>

                                <tr>
                                    <td class="d-th">15</td>
                                    <td class="d-th">60</td>
                                    <td class="d-th">95</td>
                                    <td class="d-th">50</td>
                                </tr>

                                <tr>
                                    <td class="d-th">20</td>
                                    <td class="d-th">70</td>
                                    <td class="d-th">100</td>
                                    <td class="d-th">55</td>
                                </tr>

                                <tr>
                                    <td class="d-th">25</td>
                                    <td class="d-th">80</td>
                                    <td class="d-th">120</td>
                                    <td class="d-th">65</td>
                                </tr>

                                <tr>
                                    <td class="d-th">32</td>
                                    <td class="d-th">95</td>
                                    <td class="d-th">120</td>
                                    <td class="d-th">65</td>
                                </tr>

                                <tr>
                                    <td class="d-th">40</td>
                                    <td class="d-th">110</td>
                                    <td class="d-th">135</td>
                                    <td class="d-th">85</td>
                                </tr>

                                <tr>
                                    <td class="d-th">50</td>
                                    <td class="d-th">125</td>
                                    <td class="d-th">150</td>
                                    <td class="d-th">95</td>
                                </tr>

                                <tr>
                                    <td class="d-th">65</td>
                                    <td class="d-th">160</td>
                                    <td class="d-th">170</td>
                                    <td class="d-th">120</td>
                                </tr>

                                <tr>
                                    <td class="d-th">80</td>
                                    <td class="d-th">180</td>
                                    <td class="d-th">200</td>
                                    <td class="d-th">120</td>
                                </tr>

                                <tr>
                                    <td class="d-th">100</td>
                                    <td class="d-th">216</td>
                                    <td class="d-th">230</td>
                                    <td class="d-th">140</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="product-imgage mt-1 text-center">
                    <img
                        width="250px"
                        src="assets/img/products/Products Vien Diagram/Gunmetal Globe Valve NS 382/Gunmetal Globe Valve NS 382-1.png"
                        alt="Images"
                    />
                </div>

            </div>
            <div class="notes mt-2">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to
                        continual design improvements. Please confirm before
                        placing order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends AppComponentBase implements OnInit {

  constructor(private title:Title) {
    super()
   }

  ngOnInit(): void {
    this.title.setTitle(`About | ${this.companyName}`)
  }

}

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Float Valve BPT Type
                </h4>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/FLOAT VALVE BPT TYPE.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div
            id="carouselExampleCaptions"
            class="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="3000"
        >
            <div class="carousel-indicators">
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                ></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img
                        src="assets/img/products/Float Valve BPT Type six.png"
                        style="width: 500px !important; margin: auto"
                        class="d-block"
                        alt="..."
                    />
                </div>
                <div class="carousel-item">
                    <img
                        src="assets/img/products/Float Valve BPT type three.png"
                        style="width: 501px !important; margin: auto"
                        class="d-block"
                        alt="..."
                    />
                </div>

            </div>
        </div>

        <ng-container>
            <div
                class="rectangle-bar d-flex align-items-center justify-content-between mt-5"
            >
                <div class="product-description">
                    Specification For Float Valve For Water Supply
                </div>
            </div>

            <div class="py-3 pl-14px">
                <strong
                    >There are two types of Float Valves Available in
                    Market:</strong
                >
                <ul class="ul-list">
                    <li>
                        <strong>Domestic Type Float Valve:</strong>
                        <br />There are low working pressure (0.6 to 2 kg f/cm 2
                        ) basically used in Cistern: available in every hardware
                        shops with Indian &amp; China brand. Construction of
                        these valve are not strong or to resist higher working
                        pressure.
                    </li>
                    <li>
                        <strong>BPT Type:</strong>
                        <br />These are for higher working pressure (10 kg f/cm
                        2 ) used in Break Pressure Tanks to control overflow of
                        water. <br />BPT Type PRITI Brand float valves are with
                        open type of design, the well known heavy and high class
                        quality construction, absolutely reliable operation even
                        at higher working pressure. These products are supplied:
                        Assembled complete set with Copper Ball &amp; Back Check
                        Nuts: <br /><br />
                        <div class="table-responsive">
                            <table
                                class="table table-bordered table-hover lg-table"
                            >
                                <thead>
                                    <tr>
                                        <th>S.N.</th>
                                        <th>Product Range</th>
                                        <th>1/2&quot;</th>
                                        <th>1&quot;</th>
                                        <th>1 ½”.</th>
                                        <th>2”</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            Hydrostatic Pressure Tested at kg
                                            f/cm<sup>2</sup>
                                        </td>
                                        <td>8</td>
                                        <td>10</td>
                                        <td>12</td>
                                        <td class="m-td">14</td>
                                    </tr>

                                    <tr>
                                        <td>2</td>
                                        <td>Copper Ball Swimmers Dia</td>
                                        <td>6&quot;</td>
                                        <td>8&quot;</td>
                                        <td>10&quot;.</td>
                                        <td>10&quot;</td>
                                    </tr>

                                    <tr>
                                        <td>3</td>
                                        <td>ARM Size</td>
                                        <td>22&quot;</td>
                                        <td>22&quot;</td>
                                        <td>31&quot;.</td>
                                        <td>31&quot;</td>
                                    </tr>

                                    <tr>
                                        <td>4</td>
                                        <td>Out let Dia in MM</td>
                                        <td>15</td>
                                        <td>24</td>
                                        <td>39.</td>
                                        <td>45</td>
                                    </tr>

                                    <tr>
                                        <td>5</td>
                                        <td>Overall Length</td>
                                        <td>24&quot;</td>
                                        <td>24&quot;</td>
                                        <td>33.5&quot;</td>
                                        <td>34&quot;</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table
                                class="table table-bordered table-hover sm-table"
                            >
                                <thead>
                                    <tr>
                                        <th>Product Range</th>
                                        <th>1/2&quot;</th>
                                        <th>1&quot;</th>
                                        <th>1 ½”.</th>
                                        <th>2”</th>
                                        <th>2&quot;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="m-td">
                                            Hydrostatic Pressure Tested at kg
                                            f/cm<sup>2</sup>
                                        </td>
                                        <td class="m-td">8</td>
                                        <td class="m-td">10</td>
                                        <td class="m-td">12</td>
                                        <td class="m-td">14</td>
                                        <td class="m-td">NA</td>
                                    </tr>

                                    <tr>
                                        <td class="m-td">
                                            Copper Ball Swimmers Dia
                                        </td>
                                        <td class="m-td">6&quot;</td>
                                        <td class="m-td">8&quot;</td>
                                        <td class="m-td">10&quot;.</td>
                                        <td class="m-td">10&quot;</td>
                                        <td class="m-td">NA</td>
                                    </tr>

                                    <tr>
                                        <td class="m-td">ARM Size</td>
                                        <td class="m-td">22&quot;</td>
                                        <td class="m-td">22&quot;</td>
                                        <td class="m-td">31&quot;.</td>
                                        <td class="m-td">31&quot;</td>
                                        <td class="m-td">31&quot;</td>
                                    </tr>

                                    <tr>
                                        <td class="m-td">Out let Dia in MM</td>
                                        <td class="m-td">15</td>
                                        <td class="m-td">24</td>
                                        <td class="m-td">39.</td>
                                        <td class="m-td">45</td>
                                    </tr>

                                    <tr>
                                        <td class="m-td">Overall Length</td>
                                        <td class="m-td">24&quot;</td>
                                        <td class="m-td">24&quot;</td>
                                        <td class="m-td">33.5&quot;</td>
                                        <td class="m-td">34&quot;</td>
                                        <td class="m-td">NA</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="d-none sm-table">
                                <strong
                                    >Maximum Retail Price w/o tax NRs</strong
                                >

                                <tbody>
                                    <tr>
                                        <td class="mr-td">1284</td>
                                        <td class="mr-td">2025</td>
                                        <td class="mr-td">6212</td>
                                        <td class="mr-td">6212</td>
                                        <td class="mr-td">NA</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <strong>Raw Materials: </strong>GM LG-2 Grade, Cast
                            Brass, Zinc plated MS Bolt, Nuts &amp; Copper Ball.
                        </div>
                        <div>
                            <strong>Component: </strong>
                            Body, Piston, Hanger-pin, Seat, Split-pin, Arm,
                            Ball, Check Nut, Ball-pin, Nut Bolts &amp; Washer.
                        </div>
                        <div>
                            <strong>Markings: </strong>
                            PRITI, SIZE, Direction of Flow, Made in Nepal. Also
                            Available in 3/4&quot; &amp; 1 1/4”, 2.5" & 3"
                        </div>
                    </li>
                </ul>
            </div>
        </ng-container>

        <div class="product-imgage mt-1 text-center">
            <img
                width="600px"
                src="assets/img/products/Products Vien Diagram/Float Valve BPT Type/Float Valve BPT Type-1.jpg"
                alt="Images"
            />
        </div>

        <div class="notes mt-4">
            <h5>Notes</h5>
            <ol>
                <li>
                    Product images are for illustration purposes only. Actual
                    product may vary.
                </li>
                <li>
                    Actual dimensions may change over time, due to continual
                    design improvements. Please confirm before placing order.
                </li>
            </ol>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { AppComponentBase } from '../components/shared/AppComponentBase';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent extends AppComponentBase {

    pdfs = [{ id: 1, src: 'assets/files/BRASS BIB COCK NS 362.pdf', name: 'Brass Bib Cock NS 362', date: '2023/04/16' },
    { id: 2, src: 'assets/files/GUNMETAL GATE VALVE NS 149.pdf', name: 'Gunmetal Gate Valve NS 149', date: '2023/04/16' },

    { id: 3, src: 'assets/files/GUNMETAL GLOBE VALVE NS 382.pdf', name: 'Gunmetal Globe Valve NS 382', date: '2023/04/16' },
    { id: 4, src: 'assets/files/GUNMETAL FERRULE COCK NS 361.pdf', name: 'Gunmetal Ferrule Cock NS 361', date: '2023/04/16' },


    { id: 5, src: 'assets/files/BRASS UNION LOCAL TYPE.pdf', name: 'Brass Union Local Type', date: '2023/04/16' },
    { id: 6, src: 'assets/files/BRASS UNION UNF TYPE.pdf', name: 'Brass Union UNF Type', date: '2023/04/16' },


    { id: 7, src: 'assets/files/FLOW CONTROL VALVE.pdf', name: 'Flow Control Valve', date: '2023/04/16' },
    { id: 8, src: 'assets/files/FLOAT VALVE BPT TYPE.pdf', name: 'Float Valve BPT Type', date: '2023/04/16' },

    { id: 9, src: 'assets/files/GUNMETAL AIR RELEASE VALVE.pdf', name: 'Gunmetal Air Release Valve', date: '2023/04/16' },
    { id: 10, src: 'assets/files/BRASS NOZZLE COCK.pdf', name: 'Brass Nozzle Cock', date: '2023/04/16' },

    { id: 11, src: 'assets/files/BRASS BALL VALVE BUTTERFLY TYPE.pdf', name: 'Brass Ball Valve Butterfly Type', date: '2023/04/16' },
    { id: 12, src: 'assets/files/BRASS METER BALL VALVE.pdf', name: 'Brass Meter Ball Valve', date: '2023/04/16' },

    ]
    modalIndex: number

    constructor(private title: Title) {
        super();
        this.title.setTitle(`Downloads | ${this.companyName}`)
    }
}

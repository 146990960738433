<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">

            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class="bx bxs-phone"></i>
                    <div class="content">
                        <h3>Contact Number</h3>

                        <p>
                            <a href="tel:+977 25 533701 "> +977 25 533701 </a>
                        </p>
                        <span
                            ><a href="tel:+977 25 570430">
                                +977 25 570430</a
                            ></span
                        >
                        <p>
                            <a href="tel:+977 9862181898"> +977 9862181898</a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class="bx bx-map"></i>
                    <div class="content">
                        <h3>Address</h3>
                        <p>Dharan 8, Sunsari</p>
                        <span>Koshi Pradesh, Nepal 56700</span>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3"
            >
                <div class="contact-card">
                    <i class="bx bx-envelope"></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p>
                            <a href="mailto:pritivalve@gmail.com"
                                >pritivalve@gmail.com</a
                            >
                        </p>
                        <span
                            ><a href="mailto:info@pritivalve.com"
                                >info@pritivalve.com</a
                            ></span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<!-- <div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla amet bibendum ante. Vestibulum ante ipsum primis in
                    faucibus orci.
                </p>
            </div>
        </div>
    </div>
</div> -->
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contact Us</h2>
                            <p>
                                Any question or remarks? Just write us a
                                message!
                            </p>
                        </div>

                        <form
                            id="contactForm"
                            [formGroup]="contactForm"
                            (ngSubmit)="sendEmail()"
                        >
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            formControlName="name"
                                            class="form-control"
                                            required
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            formControlName="email"
                                            class="form-control"
                                            required
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"
                                            formControlName="phone"
                                            required
                                            class="form-control"
                                            placeholder="Phone"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="msg_subject"
                                            formControlName="subject"
                                            id="msg_subject"
                                            class="form-control"
                                            required
                                            placeholder="Your Subject"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea
                                            name="message"
                                            class="form-control"
                                            id="message"
                                            formControlName="message"
                                            cols="30"
                                            rows="8"
                                            required
                                            placeholder="Your Message"
                                        ></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" [disabled]="contactForm.invalid" class="default-btn">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<div class="map-area">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1937.7169491035606!2d87.28823405632924!3d26.792371426987227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ef412bfea71f6d%3A0xebaa90736489040c!2sPritivalve%20%26%20Fitting%20Industries%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1682674676388!5m2!1sen!2snp"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
</div>
<!-- Map Area End -->

<!-- Service Area -->
<div class="service-area-two pt-4 pb-70">
    <div class="container">
        <div class="about-content mb-0">
            <h2><b>Sanitary Products</b></h2>
        </div>
        <div class="tab text-center mt-n2">
            <div class="tab_content current active">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row pt-45">
                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <div
                                            id="carouselExample"
                                            class="carousel slide"
                                        >
                                            <div class="carousel-inner">
                                                <div
                                                    class="carousel-item active"
                                                >
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Angle Cock one.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Angle Cock two.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Angle Cock three.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                            </div>
                                            <button
                                                class="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExample"
                                                data-bs-slide="prev"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>

                                                <span class="visually-hidden"
                                                    >Previous</span
                                                >
                                            </button>
                                            <button
                                                class="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExample"
                                                data-bs-slide="next"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Next</span
                                                >
                                            </button>
                                        </div>

                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/coming-soon"
                                    >
                                        <h3>
                                            <a title="CP Angle Cock"
                                                >CP Angle Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <div
                                            id="carouselExample1"
                                            class="carousel slide"
                                        >
                                            <div class="carousel-inner">
                                                <div
                                                    class="carousel-item active"
                                                >
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Short Body one.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Short Body two.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP short body three.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>

                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Short Body four.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                            </div>
                                            <button
                                                class="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExample1"
                                                data-bs-slide="prev"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Previous</span
                                                >
                                            </button>
                                            <button
                                                class="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExample1"
                                                data-bs-slide="next"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Next</span
                                                >
                                            </button>
                                        </div>

                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/coming-soon"
                                    >
                                        <h3>
                                            <a title="CP Short Body"
                                                >CP Short Body</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <div
                                            id="carouselExample2"
                                            class="carousel slide"
                                        >
                                            <div class="carousel-inner">
                                                <div
                                                    class="carousel-item active"
                                                >
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Long Body one.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Long Body two.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                            </div>
                                            <button
                                                class="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExample2"
                                                data-bs-slide="prev"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Previous</span
                                                >
                                            </button>
                                            <button
                                                class="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExample2"
                                                data-bs-slide="next"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Next</span
                                                >
                                            </button>
                                        </div>

                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/coming-soon"
                                    >
                                        <h3>
                                            <a title="CP Long Body"
                                                >CP Long Body</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <div
                                            id="carouselExample3"
                                            class="carousel slide"
                                        >
                                            <div class="carousel-inner">
                                                <div
                                                    class="carousel-item active"
                                                >
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Pillar Cock one.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Pillar Cock two.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Pillar Cock three.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>

                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Pillar Cock four.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                            </div>
                                            <button
                                                class="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExample3"
                                                data-bs-slide="prev"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Previous</span
                                                >
                                            </button>
                                            <button
                                                class="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExample3"
                                                data-bs-slide="next"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Next</span
                                                >
                                            </button>
                                        </div>

                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/coming-soon"
                                    >
                                        <h3>
                                            <a title="CP Pillar Cock"
                                                >CP Pillar Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <div
                                            id="carouselExample4"
                                            class="carousel slide"
                                        >
                                            <div class="carousel-inner">
                                                <div
                                                    class="carousel-item active"
                                                >
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Conceal Cock one.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Conceal Cock three.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>

                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Conceal Cock four.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                            </div>
                                            <button
                                                class="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExample4"
                                                data-bs-slide="prev"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Previous</span
                                                >
                                            </button>
                                            <button
                                                class="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExample4"
                                                data-bs-slide="next"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Next</span
                                                >
                                            </button>
                                        </div>

                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/coming-soon"
                                    >
                                        <h3>
                                            <a title="CP Conceal Cock"
                                                >CP Concealed Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="service-item"
                                    routerLink="/coming-soon"
                                >
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/CP Sink Cock.png"
                                                alt="Images"
                                        /></a>
                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a title="CP Sink Cock"
                                                >CP Sink Cock</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <div
                                            id="carouselExample5"
                                            class="carousel slide"
                                        >
                                            <div class="carousel-inner">
                                                <div
                                                    class="carousel-item active"
                                                >
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Swan Neck one.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a
                                                        ><img
                                                            src="assets/img/products/CP Swan Neck two.png"
                                                            alt="Images"
                                                    /></a>
                                                </div>
                                            </div>
                                            <button
                                                class="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExample5"
                                                data-bs-slide="prev"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-left"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Previous</span
                                                >
                                            </button>
                                            <button
                                                class="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExample5"
                                                data-bs-slide="next"
                                            >
                                                <i
                                                    class="fa-solid fa-angle-right"
                                                    aria-hidden="true"
                                                ></i>
                                                <span class="visually-hidden"
                                                    >Next</span
                                                >
                                            </button>
                                        </div>

                                        <a
                                            routerLink="/coming-soon"
                                            class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a class="service-item-more"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/coming-soon"
                                    >
                                        <h3>
                                            <a title="CP Swan Neck"
                                                >CP Swan Neck</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                    <span
                                        class="page-numbers current"
                                        aria-current="page"
                                        >1</span
                                    >
                                    <a
                                        routerLink="/coming-soon"
                                        class="page-numbers"
                                        >2</a
                                    >
                                    <a
                                        routerLink="/coming-soon"
                                        class="page-numbers"
                                        >3</a
                                    >
                                    <a
                                        routerLink="/coming-soon"
                                        class="next page-numbers"
                                        >Next
                                        <i class="bx bx-right-arrow-alt"></i
                                    ></a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Service Area End -->

<!-- Modal -->
<div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content rounded-0 border-0">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Brass Union
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    #btnClose
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="service-area-two">
                    <div class="container">
                        <div class="tab project-tab text-center">
                            <div class="tab_content current active">
                                <div class="tabs_item current">
                                    <div class="project-tab-item pt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div
                                                    class="service-item"
                                                    routerLink="/coming-soon"
                                                >
                                                    <div class="images">
                                                        <a
                                                            routerLink="#/brass-union-ordinary"
                                                            ><img
                                                                src="assets/img/services/services-item5.jpg"
                                                                alt="Images"
                                                        /></a>
                                                        <a
                                                            routerLink="/coming-soon"
                                                            class="service-item-icon"
                                                            ><i
                                                                class="flaticon-chess-pieces"
                                                            ></i
                                                        ></a>
                                                        <a
                                                            routerLink="#/brass-union-ordinary"
                                                            class="service-item-more"
                                                            ><i
                                                                class="flaticon-double-right-arrows-angles"
                                                            ></i
                                                        ></a>
                                                    </div>

                                                    <div class="content">
                                                        <h3>
                                                            <a
                                                                routerLink="#/brass-union-ordinary'"
                                                                >Brass Union
                                                                Ordinary</a
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6">
                                                <div
                                                    class="service-item"
                                                    routerLink="/coming-soon"
                                                >
                                                    <div class="images">
                                                        <a
                                                            routerLink="#/brass-union-unicef-type"
                                                            ><img
                                                                src="assets/img/services/services-item5.jpg"
                                                                alt="Images"
                                                        /></a>
                                                        <a
                                                            routerLink="/coming-soon"
                                                            class="service-item-icon"
                                                            ><i
                                                                class="flaticon-chess-pieces"
                                                            ></i
                                                        ></a>
                                                        <a
                                                            routerLink="#/brass-union-unicef-type"
                                                            class="service-item-more"
                                                            ><i
                                                                class="flaticon-double-right-arrows-angles"
                                                            ></i
                                                        ></a>
                                                    </div>

                                                    <div class="content">
                                                        <h3>
                                                            <a
                                                                routerLink="#/brass-union-unicef-type'"
                                                                >Brass Union
                                                                UNICEF Type</a
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Gunmetal Ferrule Cock NS 361
                </h4>
                <div>NS-361/053, NS MARKED</div>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/GUNMETAL FERRULE COCK NS 361.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img
                                    style="margin-left: 95px"
                                    [src]="slide.src"
                                    [alt]="slide.alt"
                                />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">Specification</div>
                    </div>

                    <div class="py-3 pl-14px">
                        &#39;PRITI&#39; Gun Metal Ferrule as per NS-361/053 ;
                        Screw male ends (BSPT) as per IS:554, Ferrules are
                        supplied with rough finish.
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Test Pressure = 20 kg f/cm<sup>2</sup>
                                </li>
                            </ul>
                        </div>
                        <p class="mt-n20px">
                            All Ferrules are supplied duly NS Marked.
                        </p>
                        <p class="mt-n20px">
                            Our Works Test Certificate is furnished with every
                            supply.
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials">
            <h5>Materials</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                            <th>Material Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="m-td">Body, Plug &amp; Cap</td>
                            <td class="m-td">Gun Metal/Leaded Tin Bronzes</td>
                            <td class="m-td">GM/LTB2</td>
                        </tr>

                        <tr>
                            <td class="m-td">Washer Plate &amp; Nut</td>
                            <td class="m-td">High Tensile Brass</td>
                            <td class="m-td">HTB</td>
                        </tr>

                        <tr>
                            <td class="m-td">Gasket</td>
                            <td class="m-td">Compressed Asbestos Fibre</td>
                            <td class="m-td">CAF</td>
                        </tr>
                        <tr>
                            <td class="m-td">Rubber Washer</td>
                            <td class="m-td">Natural Rubber</td>
                            <td class="m-td">NR</td>
                        </tr>
                        <tr>
                            <td class="m-td">Copper Washer</td>
                            <td class="m-td">Copper Sheet</td>
                            <td class="m-td">Cu</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Dimensions (in mm)</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nominal Size</th>
                                    <th>R <br />(Minimum)</th>
                                    <th>S <br />(Minimum)</th>
                                    <th>T <br />(Minimum)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="d-td">15</td>
                                    <td class="d-td">12.7</td>
                                    <td class="d-td">45</td>
                                    <td class="d-td">55</td>
                                </tr>

                                <tr>
                                    <td class="d-td">20</td>
                                    <td class="d-td">18.9</td>
                                    <td class="d-td">55</td>
                                    <td class="d-td">70</td>
                                </tr>

                                <tr>
                                    <td class="d-td">25</td>
                                    <td class="d-td">25.2</td>
                                    <td class="d-td">70</td>
                                    <td class="d-td">75</td>
                                </tr>

                                <tr>
                                    <td class="d-td">32</td>
                                    <td class="d-td">31.5</td>
                                    <td class="d-td">85</td>
                                    <td class="d-td">90</td>
                                </tr>

                                <tr>
                                    <td class="d-td">40</td>
                                    <td class="d-td">31.8</td>
                                    <td class="d-td">95</td>
                                    <td class="d-td">100</td>
                                </tr>

                                <tr>
                                    <td class="d-td">50</td>
                                    <td class="d-td">50.4</td>
                                    <td class="d-td">115</td>
                                    <td class="d-td">120</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="product-imgage mt-1 text-center">
                    <img
                        width="300px"
                        src="assets/img/products/Products Vien Diagram/Gunmetal Ferrule Cock NS 381/Gunmetal Ferrule Cock NS 381-1.png"
                        alt="Images"
                    />
                </div>
            </div>
            <div class="notes">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to continual
                        design improvements. Please confirm before placing
                        order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
    selector: 'app-bpt-type-float-valve',
    templateUrl: './bpt-type-float-valve.component.html',
    styleUrls: ['./bpt-type-float-valve.component.scss']
})
export class BptTypeFloatValveComponent extends AppComponentBase {

    constructor(private title: Title) {
        super();
        this.title.setTitle(`Float Valve BPT Type | ${this.companyName}`)

    }
}

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    BRASS BIB COCK NS 362
                </h4>
                <div>NS - 362/053, NS marked</div>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/BRASS BIB COCK NS 362.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img product-img-md-sm">
                    <img
                        src="/assets/img/products/Brass Bib Cock NS 361.png"
                        alt="Images"
                    />
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">Specification</div>
                    </div>

                    <div class="py-3 pl-14px">
                        &#39;PRITI&#39; Cast Brass Bib Tap: Screwed Male (BSP)
                        Inlet Confirming to NS-362/053. Bib Taps are supplied
                        with Polished Body. However Chromium Plated Taps can be
                        supplied on request at extra cost.
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    All Bib Taps are tested to 2 MPa Hyd.
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    These taps are duly NS Marked.
                                </li>
                                <li>
                                    <i class="bx bx-check"></i> Our Works Test
                                    Certificate is furnished with every supply.
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials">
            <h5>Materials</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                            <th>Material Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="m-td align-middle" rowspan="2">
                                Body &amp; Bonnet Gland, Crutch, Washer Plate
                            </td>
                            <td class="m-td">Cast Brass</td>
                            <td class="m-td">CB</td>
                        </tr>

                        <tr>
                            <td
                                class="m-td align-middle sm-td d-none"
                                rowspan="2"
                            >
                                Body &amp; Bonnet Gland, Crutch, Washer Plate
                            </td>
                            <td class="m-td">Diet Cast Brass</td>
                            <td class="m-td">DCB</td>
                        </tr>

                        <tr>
                            <td class="m-td">Spindle &amp; Nut</td>
                            <td class="m-td">Forged Brass</td>
                            <td class="m-td">FB</td>
                        </tr>
                        <tr>
                            <td class="m-td">Gland Packing</td>
                            <td class="m-td">Asbestos</td>
                            <td class="m-td">ASB</td>
                        </tr>
                        <tr>
                            <td class="m-td">Rubber Washer</td>
                            <td class="m-td">Natural Rubber</td>
                            <td class="m-td">NR</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Dimensions (in mm)</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nominal Size</th>
                                    <th>A</th>
                                    <th>B & C</th>
                                    <th>Mass</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="d-td">15</td>
                                    <td class="d-td">54</td>
                                    <td class="d-td">13</td>
                                    <td class="d-td">400 gm</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="product-imgage mt-1 text-center">
                    <img
                        width="200px"
                        src="assets/img/products/Products Vien Diagram/Brass Bib Cock NS 361/Brass Bib Cock NS 361-1.png"
                        alt="Images"
                    />
                </div>
            </div>
            <div class="notes">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to continual
                        design improvements. Please confirm before placing
                        order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Flow Control Valve
                </h4>
            </div>
            <div class="two d-flex">
                <div class="about-btn">
                    <a href="assets/files/FLOW CONTROL VALVE.pdf" download class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img style="margin-left: 95px;" [src]="slide.src" [alt]="slide.alt" />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">Scope</div>
                    </div>

                    <div class="py-3 pl-14px">
                        One of the most innovative and very important name for
                        the Water Supply System is Flow Regulating Valve, newly
                        designed by <strong>FINIDA</strong> &amp;
                        <strong>UNICEF</strong> consultants to use in Water
                        Supply Systems in Nepal. <br /><br />These types of
                        valves reduce the cost of project because there in no
                        need of Valve Box, Key for Valve Box, Ferrule, Gate or
                        Globe Valve after using Flow Regulating Valve. Due to
                        the competition in the local market low quality Indian
                        valves with maximum % of Lead portion are available
                        which does not work on high pressure, whereas Flow
                        Regulating valves are constructed only with 3(three)
                        parts from the best quality Gun Metals &amp; which can
                        withstand High working pressure easily. These valves are
                        operated only by special key so there is another benefit
                        to the project to control the volume of water to be
                        supplied and to reduce the wastage of valuable water.
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <div class="choose-item py-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Body Test Pressure = 30 kg f/cm<sup>2</sup>
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Seat &amp; Backseat Test Pressure = 20 kg
                                    f/cm<sup>2</sup>
                                </li>
                            </ul>
                        </div>
                        <p class="mt-n20px">
                            Our Works Test Certificate is furnished with every
                            supply.
                        </p>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">Marks</div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        All Valves are supplied duly PRITI Flow Arrow &amp; Size
                        Marked on the body.
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials mt-4">
            <h5>Materials</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="m-td">Body, Cap, Regulator</td>
                            <td class="m-td">Gun Metal/Leaded Tin Bronze</td>
                        </tr>

                        <tr>
                            <td class="m-td">&#39;0&#39; Ring</td>
                            <td class="m-td">PVC Best Quality</td>
                        </tr>

                        <tr>
                            <td class="m-td">Key</td>
                            <td class="m-td">Nickel Plated on Mild Steel</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Dimensions (in mm)</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nominal Size</th>
                                    <th>F</th>
                                    <th>H</th>
                                    <th>W</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="d-td">15</td>
                                    <td class="d-td">57.0</td>
                                    <td class="d-td">90.0</td>
                                    <td class="d-td">22.0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div class="product-image mt-1 text-center">
                            <img
                                width="270px"
                                src="assets/img/products/Products Vien Diagram/Priti Regulating Flow Control Valve/Priti Regulating Flow Control Valve-1.png"
                                alt="Images"
                            />
                        </div>
                    </div>

                </div>



            </div>

            <div class="notes mt-4">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to
                        continual design improvements. Please confirm before
                        placing order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

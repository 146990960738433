import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from 'src/app/components/shared/AppComponentBase';

@Component({
    selector: 'app-brass-union-ordinary',
    templateUrl: './brass-union-ordinary.component.html',
    styleUrls: ['./brass-union-ordinary.component.scss']
})
export class BrassUnionOrdinaryComponent extends AppComponentBase {
    images = [
        { id: 1, src: "assets/img/products/Brass Union Local Type one.png", alt: 'img' },
        { id: 2, src: "assets/img/products/Brass Union Local Type two.png", alt: 'img' },

    ]


    constructor(private title: Title) {
        super();
        this.title.setTitle(`Brass Union Local Type | ${this.companyName}`)
    }
}

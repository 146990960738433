<!-- Service Area -->
<div class="service-area-two pt-4 pb-70">
    <div class="container">
        <div class="tab project-tab text-center">
            <ul class="tabs">
                <!-- <li><a href="#">General</a></li> -->
                <!-- <li><a href="#">Sanitary</a></li> -->
            </ul>

            <div class="tab_content current active pt-3">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div
                                class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-3" *ngFor="let item of pdfs;index as i"
                            >
                                <div class="card">
                                    <strong class="download-title"
                                        >{{item.name}}</strong
                                    >
                                    <div>{{item.date}}</div>
                                    <div class="mt-3">
                                        <button
                                            class="button-61"
                                            (click)="modalIndex=i"
                                            role="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content rounded-0 border-0">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                    {{pdfs[modalIndex]?.name}}
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <pdf-viewer
                    [src]="pdfs[modalIndex]?.src"
                    [render-text]="true"
                    [original-size]="false"
                    style="width: 100%; height: 500px"
                ></pdf-viewer>
            </div>
            <div class="modal-footer">
                <a  [href]="pdfs[modalIndex]?.src" title="Download File" download class="button-61"><i class="fa-solid fa-download fs-5"></i></a>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppComponentBase } from '../../shared/AppComponentBase';

@Component({
  selector: 'app-nozzle-cock',
  templateUrl: './nozzle-cock.component.html',
  styleUrls: ['./nozzle-cock.component.scss']
})
export class NozzleCockComponent extends AppComponentBase {
    constructor(private title: Title) {
        super();
        this.title.setTitle(`Brass Nozzle Cock | ${this.companyName}`)
    }
}

<!-- Service Area -->
<div class="service-area-two pt-4 pb-70">
    <div class="container">
        <div class="about-content">
            <h2><b>Valve & Fittings Products</b></h2>
        </div>
        <div class="tab project-tab text-center">
            <div class="tab_content current active">
                <div class="tabs_item current">
                    <div class="project-tab-item pt-4">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Brass Bib Cock NS 361.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/brass-bib-tap"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/brass-bib-tap"
                                    >
                                        <h3 title="Brass Bib Cock NS 362">
                                            <a>Brass Bib Cock NS 362</a>
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Gunmetal Gate Valve NS 149 three.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/gate-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/gate-valve"
                                    >
                                        <h3>
                                            <a
                                                class="white-space-no-wrap"
                                                title="Gunmetal Gate Valve NS 149"
                                                >Gunmetal Gate Valve...</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Gunmetal Globe Valve NS 382 two.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/globe-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/globe-valve"
                                    >
                                        <h3
                                            title="Gun Metal Globe Valve NS 382"
                                        >
                                            <a class="white-space-no-wrap"
                                                >Gun Metal Globe Valve...</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Gunmetal Ferrule Cock NS 381 two.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/ferulle-cock"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/ferulle-cock"
                                    >
                                        <h3>
                                            <a
                                                title="Gunmetal Ferrule Cock NS 361"
                                                >Gunmetal Ferrule Cock...</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="service-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                >
                                    <div class="images">
                                        <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            ><img
                                                src="assets/img/products/Brass Union Local Type one.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            class="service-item-more cursor-pointer"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div class="content">
                                        <h3>
                                            <a
                                                title="Brass Union"
                                                class="cursor-pointer"
                                                >Brass Union</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Priti Regulating Flow Control Valve two.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/flow-regulating-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/flow-regulating-valve"
                                    >
                                        <h3>
                                            <a
                                                title="Flow Control Valve"
                                                >Flow Control Valve</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Float Valve BPT Type six.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/bptType-float-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/bptType-float-valve"
                                    >
                                        <h3>
                                            <a title="Float Valve BPT Type"
                                                >Float Valve BPT Type</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Gunmetal Air Release Valve.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/air-release-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/air-release-valve"
                                    >
                                        <h3 title="Gunmetal Air Release Valve">
                                            <a>Gunmetal Air Release... </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Brass Nozzle Cock.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/nozzle-cock"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/nozzle-cock"
                                    >
                                        <h3 title="Brass Nozzle Cock">
                                            <a>Brass Nozzle Cock</a>
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Brass Ball Valve Butterfly type one.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/brass-ball-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/brass-ball-valve"
                                    >
                                        <h3>
                                            <a
                                                class="white-space-no-wrap"
                                                title="Brass Ball Valve Butterfly Type"
                                                >Brass Ball Valve
                                                Butterfly...</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item">
                                    <div class="images">
                                        <a
                                            ><img
                                                src="assets/img/products/Brass Meter Ball Valve three.png"
                                                alt="Images"
                                        /></a>
                                        <a class="service-item-icon"
                                            ><i class="fa-solid fa-filter"></i
                                        ></a>
                                        <a
                                            class="service-item-more"
                                            routerLink="/product-details/meter-ball-valve"
                                            ><i
                                                class="flaticon-double-right-arrows-angles"
                                            ></i
                                        ></a>
                                    </div>

                                    <div
                                        class="content"
                                        routerLink="/product-details/meter-ball-valve"
                                    >
                                        <h3>
                                            <a title="Brass Meter Ball Valve"
                                                >Brass Meter Ball Valve</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                    <span
                                        class="page-numbers current"
                                        aria-current="page"
                                        >1</span
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="page-numbers"
                                        >2</a
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="page-numbers"
                                        >3</a
                                    >
                                    <a
                                        routerLink="/services-2"
                                        class="next page-numbers"
                                        >Next
                                        <i class="bx bx-right-arrow-alt"></i
                                    ></a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Service Area End -->

<!-- Modal -->
<div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content rounded-0 border-0">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Brass Union
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    #btnClose
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="service-area-two">
                    <div class="container">
                        <div class="tab project-tab text-center">
                            <div class="tab_content current active">
                                <div class="tabs_item current">
                                    <div class="project-tab-item pt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="service-item">
                                                    <div class="images">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/Brass Union Local Type one.png"
                                                                alt="Images"
                                                        /></a>
                                                        <a
                                                            class="service-item-icon"
                                                            ><i
                                                                class="fa-solid fa-filter"
                                                            ></i
                                                        ></a>
                                                        <a
                                                            class="service-item-more"
                                                            routerLink="/product-details/brass-union-ordinary"
                                                            ><i
                                                                class="flaticon-double-right-arrows-angles"
                                                            ></i
                                                        ></a>
                                                    </div>

                                                    <div
                                                        class="content"
                                                        routerLink="/product-details/brass-union-ordinary"
                                                    >
                                                        <h3>
                                                            <a
                                                                title="Brass Union Local Type"
                                                                >Brass Union
                                                                Local Type</a
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6">
                                                <div class="service-item">
                                                    <div class="images">
                                                        <a
                                                            ><img
                                                                src="assets/img/products/Brass Union UNF type one.png"
                                                                alt="Images"
                                                        /></a>
                                                        <a
                                                            class="service-item-icon"
                                                            ><i
                                                                class="fa-solid fa-filter"
                                                            ></i
                                                        ></a>
                                                        <a
                                                            class="service-item-more"
                                                            routerLink="/product-details/brass-union-unicef-type"
                                                            ><i
                                                                class="flaticon-double-right-arrows-angles"
                                                            ></i
                                                        ></a>
                                                    </div>

                                                    <div
                                                        class="content"
                                                        routerLink="/product-details/brass-union-unicef-type"
                                                    >
                                                        <h3>
                                                            <a
                                                                title="Brass Union UNF Type"
                                                                >Brass Union UNF
                                                                Type</a
                                                            >
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

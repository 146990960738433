<!-- Project Details Area -->
<div class="project-details-area pt-5">
    <div class="container">
        <div
            class="about-content d-flex justify-content-between flex-wrap align-items-baseline"
        >
            <div class="one">
                <h4 class="product-name text-uppercase">
                    Brass Ball Valve Butterfly Type
                </h4>
            </div>

            <div class="two d-flex">
                <div class="about-btn">
                    <a download href="assets/files/BRASS BALL VALVE BUTTERFLY TYPE.pdf" class="learn-btn"
                        >Download</a
                    >
                </div>

                <div class="about-btn">
                    <a routerLink="/contact" class="get-btn">Get a Quote</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <div class="product-img">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of images">
                            <ng-template carouselSlide [id]="slide.id">
                                <img
                                    style="margin-left: 95px"
                                    [src]="slide.src"
                                    [alt]="slide.alt"
                                />
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
            >
                <!-- Product Description -->
                <ng-container>
                    <div
                        class="rectangle-bar d-flex align-items-center justify-content-between"
                    >
                        <div class="product-description">
                            Product Description
                        </div>
                    </div>

                    <div class="py-3 pl-14px">
                        <ul class="list-unstyled">
                            <li>
                                <strong>Product:</strong> Priti Brass Ball Valve
                                with ‘T’ Handle
                            </li>
                            <li><strong>Pressure Rating:</strong> PN-25</li>
                            <li>
                                <strong>Body Material Cum Standard:</strong>
                                Brass
                            </li>
                            <li>
                                <strong>Product Description:</strong>
                                Two piece design ‘T’ Handle Operated
                            </li>
                            <li>
                                <strong>Type of Seat:</strong> Renewable PTFE
                                Seats
                            </li>
                            <li>
                                <strong>Type of Ball:</strong> Brass (Nickel
                                Plated)
                            </li>
                            <li>
                                <strong>Stem:</strong> Machined Brass (Nickel
                                Plated)
                            </li>
                            <li><strong>Packing:</strong> PTFE</li>
                            <li><strong>Port Opening:</strong> Full Bore</li>
                            <li>
                                <strong>End Details:</strong> Screwed Female BSP
                                Parallel Threads
                            </li>
                        </ul>
                    </div>
                </ng-container>

                <!-- Tesiting & Certification -->
                <ng-container>
                    <div class="rectangle-bar">
                        <div class="product-description">
                            Testing & Certification
                        </div>
                    </div>

                    <div class="choose-item pt-3 pl-14px">
                        <div class="choose-item-list">
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    All ball valves are tested to 2MPa (Hyd.)
                                </li>

                            </ul>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Materials -->
        <div class="materials">
            <h5>Materials of Construction</h5>

            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Components</th>
                            <th>Materials</th>
                            <th>Specifications</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="m-td align-middle">Nut</td>
                            <td class="m-td">S.S.</td>
                            <td class="m-td">-</td>
                        </tr>

                        <tr>
                            <td class="m-td">Gland</td>
                            <td class="m-td">Brass</td>
                            <td class="m-td">-</td>
                        </tr>

                        <tr>
                            <td class="m-td">Gland Seal</td>
                            <td class="m-td">PTFE</td>
                            <td class="m-td">-</td>
                        </tr>
                        <tr>
                            <td class="m-td">Connector</td>
                            <td class="m-td">Forged Brass</td>
                            <td class="m-td">-</td>
                        </tr>
                        <tr>
                            <td class="m-td">Seat</td>
                            <td class="m-td">PTFE</td>
                            <td class="m-td">-</td>
                        </tr>

                        <tr>
                            <td class="m-td">Ball</td>
                            <td class="m-td">Forged</td>
                            <td class="m-td">-</td>
                        </tr>

                        <tr>
                            <td class="m-td">Stem</td>
                            <td class="m-td">Brass</td>
                            <td class="m-td">-</td>
                        </tr>

                        <tr>
                            <td class="m-td">Body</td>
                            <td class="m-td">Forged Brass</td>
                            <td class="m-td">-</td>
                        </tr>

                        <tr>
                            <td class="m-td">‘T’ Handle</td>
                            <td class="m-td">Aluminium</td>
                            <td class="m-td">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Dimensions -->
        <div class="row dimensions mt-2">
            <div
                class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
                <div>
                    <h5>Dimensions (in mm)</h5>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nominal Size</th>
                                    <th>Nominal Size <br />(Inches)</th>
                                    <th>F±2</th>
                                    <th>T (Approx.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="d-td">15</td>
                                    <td class="d-td">1/2</td>
                                    <td class="d-td">55</td>
                                    <td class="d-td">60</td>
                                </tr>

                                <tr>
                                    <td class="d-td">20</td>
                                    <td class="d-td">3/4</td>
                                    <td class="d-td">65</td>
                                    <td class="d-td">60</td>
                                </tr>

                                <tr>
                                    <td class="d-td">25</td>
                                    <td class="d-td">1</td>
                                    <td class="d-td">75</td>
                                    <td class="d-td">70</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="product-imgage mt-1 text-center">
                        <img
                            width="200px"
                            src="assets/img/products/Products Vien Diagram/​​Brass Ball Valve with T Handle/​​Brass Ball Valve with T Handle.png"
                            alt="Images"
                        />
                    </div>
                </div>
            </div>

            <div class="notes mt-2">
                <h5>Notes</h5>
                <ol>
                    <li>
                        Product images are for illustration purposes only.
                        Actual product may vary.
                    </li>
                    <li>
                        Actual dimensions may change over time, due to continual
                        design improvements. Please confirm before placing
                        order.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
